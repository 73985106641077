import { isEqual, throttle } from 'lodash-es';
import { isProd } from './env';

const oneHour = 60 * 60 * 1000;
const _queueMicrotask: typeof queueMicrotask =
  typeof queueMicrotask === 'function' ? queueMicrotask : (fn) => Promise.resolve().then(fn);
type PromiseFn<A extends any[], R> = (...args: A) => Promise<R>;

/**
 * 将相同传参 (深比较) 的promise调用合并
 * @param {Function} promiseFn
 * @param {object} opt
 * @param {boolean} opt.persist 是否缓存调用结果
 * @param {boolean} opt.persistOnReject 再发生错误时, 是否任缓存调用结果
 * @param {Function} opt.argComparer 参数比较函数, 返回true代表参数一致
 * @param {number} opt.ttl 缓存生存时间 0代表永不过期
 * @param {boolean} opt.useMapCache 使用Map缓存调用结果 (默认会使用WeakMap缓存以避免占用过多内存)
 */
export default function mergePromiseCall<A extends any[], R>(
  promiseFn: PromiseFn<A, R>,
  {
    persist = false,
    persistOnReject = false,
    argComparer = isEqual,
    ttl = oneHour / 3,
    useMapCache = false,
  } = {},
): PromiseFn<A, R> {
  if (!isProd) {
    console.log('%c非正式环境下，关闭接口请求缓存', 'color:#fff;background: #11ddcc');
    persist = false;
  }

  const _pending = new Map<A, [((value: R) => void)[], ((reason?: any) => void)[]]>(); // arg: [[resolve, ...], [reject, ...]]
  const _resultKeys = new Set<[A, number]>(); // [[arg, time], ...]
  const _result = useMapCache ? new Map<A, [R?, Error?]>() : new WeakMap<A, [R?, Error?]>(); // arg: [result, error]

  const callGC = throttle(() => {
    const now = +new Date();
    [..._resultKeys].forEach((i) => {
      if (!_result.has(i[0]) || (ttl && now - i[1] > ttl)) {
        _result.delete(i[0]);
        _resultKeys.delete(i);
      }
    });
  }, oneHour / 5);

  return function mergedPromise(...args: A) {
    return new Promise((resolve, reject) => {
      _queueMicrotask(callGC);

      if (persist) {
        const resultKey = [..._resultKeys].find((i) => argComparer(i[0], args));
        if (resultKey) {
          if (!ttl || +new Date() - resultKey[1] <= ttl) {
            const result = _result.get(resultKey[0]);
            if (result) {
              if (result[1]) reject(result[1]);
              else resolve(result[0]!);
              return;
            }
          }

          _result.delete(resultKey[0]);
          _resultKeys.delete(resultKey);
        }
      }

      let pendingKey = [..._pending.keys()].find((i) => argComparer(i, args));
      if (pendingKey) {
        const pending = _pending.get(pendingKey)!;
        pending[0].push(resolve);
        pending[1].push(reject);
        return;
      }

      pendingKey = args;
      const pendingValue = [[resolve], [reject]] as [
        ((value: any) => void)[],
        ((reason?: any) => void)[],
      ];
      _pending.set(pendingKey, pendingValue);
      promiseFn(...args)
        .then((res) => {
          pendingValue[0].forEach((fn) => fn(res));
          _pending.delete(pendingKey!);
          if (persist) {
            _resultKeys.add([pendingKey!, +new Date()]);
            _result.set(pendingKey!, [res]);
          }
        })
        .catch((e) => {
          pendingValue[1].forEach((fn) => fn(e));
          _pending.delete(pendingKey!);
          if (persist && persistOnReject) {
            _resultKeys.add([pendingKey!, +new Date()]);
            _result.set(pendingKey!, [undefined, e]);
          }
        });
    });
  };
}
