import modelStore from './model';
import aibotStore from './aibot';
import userInfoStore from './userInfo';
import { makeAutoObservable, when } from 'mobx';
import { getIsClient } from '@/utils/env';

class HubStore {
  aibotStore: typeof aibotStore | null = null;

  modelStore: typeof modelStore | null = null;

  userInfoStore: typeof userInfoStore | null = null;

  get storeDataReady(): boolean {
    return !!(hubStore.aibotStore?.classifyList?.length && hubStore.modelStore?.list?.length);
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  set(v: Partial<HubStore>) {
    Object.assign(this, v);
  }

  whenStoreDataReady() {
    return when(() => this.storeDataReady);
  }
}

const hubStore = new HubStore();
export default hubStore;

// for debug
if (getIsClient()) {
  (window as any).__hubStore = hubStore;
}
