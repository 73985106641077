import STYLE from './index.module.less';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import clx from 'classnames';

function CloseBtn({ onClick = () => {}, className = '' }) {
  return (
    <Button
      className={clx(STYLE.closeBtn, { [STYLE[className]]: !!className })}
      type="text"
      icon={
        <CloseOutlined
          style={{ color: className === 'black' ? 'rgba(255, 255, 255, 0.8)' : undefined }}
        />
      }
      onClick={onClick}
    />
  );
}

export default CloseBtn;
