export default function imageLoader({ src, width, quality = 95 }) {
  if (!src.startsWith('http') && !src.startsWith('//')) {
    return src;
  }

  const url = new URL(src);
  const allowHostnames = ['img.changwenai.com', 'img.changwenai.com', 'img.changwenai.com'];

  if (!url.pathname.includes('!') && !url.search && allowHostnames.includes(url.hostname)) {
    return `${src}?imageMogr2/thumbnail/${width}x/quality/${quality}`;
  } else {
    return src;
  }
}
