import { action, makeAutoObservable, makeObservable, observable, when } from 'mobx';
import { ModelDetail } from '@/services/model';
import { FetchApi } from '@/utils/fetchApi';
import userInfoStore from './userInfo';
import { message } from '@/configs/theme';
import { setExtraMapForAIWordNum } from '@/utils/buryPoint/extra';
import { AIBotInfo, UserAIBotInfo } from '@/services/aibot';
import { ChatData, ChatType, chat, createChatItem, getChatList } from '@/services/chat';
import { groupListStore } from '@/coms/groupList';
import hubStore from './hub';

export type ChatStoreType = ChatStore;

export type ListType = AwaitedReturnType<typeof getChatList>['list'] & { vip_limit?: boolean };

class ChatStore {
  readonly chatId: number;
  modelDetail: ModelDetail | undefined;
  aibotInfo: AIBotInfo | undefined;
  userBotInfo: UserAIBotInfo | undefined;

  docId: number | undefined;

  list: ListType | null = null;
  listCount = 0;

  get inited() {
    return !!this.list;
  }

  get modelId() {
    return this.modelDetail?.id;
  }

  get botId() {
    return this.aibotInfo?.id;
  }

  get userBotId() {
    return this.userBotInfo?.user_bot_id;
  }

  get isVip() {
    return this.modelDetail?.is_vip === 1 || this.aibotInfo?.vip_type === 1;
  }

  get isAIBotHelper() {
    return this.aibotInfo?.type_id === 2;
  }

  listFetching = false;

  waiting: FetchApi | null = null; // null 表示没有等待回答中
  creatingListItem = false; // 创建对话列表项中（用户提问时）
  get answering() {
    return this.creatingListItem || !!this.waiting;
  }

  lastSSETime = 0; // 上次收到sse的时间
  timeStep = 600; // 上两次sse的时间间隔，作为下一次sse的预测时间。用作文字流式输出动画的持续时间

  constructor({
    chatId,
    modelDetail,
    aibotInfo,
    docId,
    userBotInfo,
  }: {
    chatId: number;
    modelDetail?: ModelDetail;
    aibotInfo?: AIBotInfo;
    docId?: number;
    userBotInfo?: UserAIBotInfo;
  }) {
    this.chatId = chatId;
    this.modelDetail = modelDetail;
    this.aibotInfo = aibotInfo;
    this.docId = docId;
    this.userBotInfo = userBotInfo;
    makeAutoObservable(this, {}, { autoBind: true });
    this.getList();
  }

  *getList({ forNext = true } = {}) {
    const currentRoute =
      typeof window !== 'undefined' && window.location.pathname.includes('/aibot/uNew');
    // forNext 表示是否是在请求下一页
    if (this.listFetching) return;
    this.listFetching = true;

    if (!this.list || !forNext) {
      // 第一次请求或者刷新数据
      const { list, count }: AwaitedReturnType<typeof getChatList> = yield getChatList({
        chatId: this.chatId,
        offset: 0,
        pageSize: 20,
        currentRoute: currentRoute ? true : false,
      });
      this.list = list;
      this.listCount = count;
    } else if (this.list.length < this.listCount) {
      // 请求下一页
      const { list, count }: AwaitedReturnType<typeof getChatList> = yield getChatList({
        chatId: this.chatId,
        offset: this.list.length,
        pageSize: 20,
        currentRoute: currentRoute ? true : false,
      });
      this.list.push(...list);
      this.listCount = count;
    }

    this.listFetching = false;
  }

  // 生成新的会话记录或者重新生成已有的会话记录
  *chat({ text, recordId, isMulti }: { text?: string; recordId?: number; isMulti?: boolean }) {
    if (userInfoStore.isBanned) {
      message.info({
        key: 'bannedMessageKey',
        content: '您的提问异常，账号已被冻结，申诉请找客服。',
      });
      return false;
    }

    if (!this.inited || this.answering) {
      return false;
    }

    if (!text && recordId) {
      const item = this.list?.find((item) => item.id === recordId);
      text = (this.isAIBotHelper ? item?.origin_prompt : item?.prompt) as string;
    }

    if (!recordId) {
      if (this.isVip && !userInfoStore.isVip) {
        this.list!.unshift({
          id: recordId,
          prompt: text,
          content: '',
          vip_limit: true,
        } as any);
        return;
      } else {
        this.creatingListItem = true;
        let chatType: ChatType;
        if (isMulti) {
          chatType = 'multi';
        } else if (this.modelId) {
          chatType = 'single';
        } else if (!this.isAIBotHelper) {
          chatType = 'aibot';
        } else if (this.userBotId) {
          chatType = 'userBot';
        } else {
          chatType = 'aibotHelper';
        }
        const currentRoute =
          typeof window !== 'undefined' && window.location.pathname.includes('/aibot/uNew');
        try {
          const { chat_record_ids, code }: AwaitedReturnType<typeof createChatItem> =
            yield createChatItem({
              chatIds: [this.chatId],
              prompt: text as string,
              modelId: this.modelId,
              botId: this.botId,
              docId: this.docId,
              userBotId: this.userBotId,
              chatType,
              currentRoute: currentRoute ? true : false,
            });
          recordId = chat_record_ids[0];
        } catch (error) {
          message.info({ key: 'shareDisabledMessageKey', content: '分享已被禁用' });
        }

        // 插入到最前面

        this.list!.unshift({
          id: recordId,
          prompt: text,
          content: '',
        });
        this.creatingListItem = false;
      }
    }

    const recordIndex = this.list!.findIndex((item) => item.id === recordId);
    const record = this.list![recordIndex];
    const previousRecord = this.list![recordIndex + 1];

    record!.content = '';

    this.lastSSETime = Date.now();
    const currentRoute =
      typeof window !== 'undefined' && window.location.pathname.includes('/aibot/uNew');
    this.waiting = chat({
      currentRoute: currentRoute ? true : false,
      chatRecordId: recordId,
      modelId: this.modelId,
      botId: this.botId,
      docId: this.docId,
      userBotId: this.userBotId,
      subject: text as string,
      previousSubject: this.isAIBotHelper ? previousRecord?.origin_prompt : previousRecord?.prompt,
      previousContent: previousRecord?.content,
      onLine: hubStore.modelStore?.modelOnLine[this.modelId ?? 0],
      onSSE: action((data: ChatData) => {
        if (!data) return;

        const now = Date.now();
        this.timeStep = Math.max(200, now - this.lastSSETime - ~~(Math.random() * 200));
        this.lastSSETime = now;

        record.content += data.content;

        if (data.search_results?.length) {
          record.search_results = data.search_results;
        }
        if (!userInfoStore.isEnjoyVip) {
          userInfoStore.updateAIWordNum(data.remaining_ai_word_num);
          userInfoStore.updateAINum(data.remaining_ai_num);
        }
      }),
    });
    this.waiting
      .catch((e) => {
        if (e.code === 103) {
          setExtraMapForAIWordNum({ modelDetail: this.modelDetail, aibotInfo: this.aibotInfo });
          userInfoStore.updateAIWordNum(0);
          userInfoStore.checkRights(undefined, ['wordNum', 'num']);
          message.info({ key: 'noAiWordNumMessageKey', content: 'AI字数已耗尽，请充值AI字数' });
        } else if (e.code === 104) {
          setExtraMapForAIWordNum({ modelDetail: this.modelDetail, aibotInfo: this.aibotInfo });
          userInfoStore.updateAINum(0);
          userInfoStore.checkRights(undefined, ['num']);
          message.info({ key: 'noAiNumMessageKey', content: 'AI字数已耗尽，请充值' });
        } else {
          throw e;
        }
      })
      .finally(
        action(() => {
          this.waiting = null;
          groupListStore.getListForLeftBar({ pageSize: 5, userBotId: this.userBotId });
        }),
      );

    return true;
  }

  abort() {
    this.waiting?.abort();
    this.waiting = null;
  }

  dispose() {
    this.abort();
  }
}

class ChatMapStore {
  chatStoreMap: Record<number, ChatStore> = {};

  constructor() {
    makeObservable(
      this,
      {
        chatStoreMap: observable,
        createChatStore: action,
        whenChatStoreReady: action,
        disposeChatStore: action,
        disposeAllChatStore: action,
        chatAll: action,
        regenerateChatRecode: action,
        abortAll: action,
      },
      { autoBind: true },
    );
  }

  createChatStore({
    chatId,
    modelDetail,
    aibotInfo,
    docId,
    userBotInfo,
  }: {
    chatId: number;
    modelDetail?: ModelDetail;
    aibotInfo?: AIBotInfo;
    docId?: number;
    userBotInfo?: UserAIBotInfo;
  }) {
    if (this.chatStoreMap[chatId]) {
      const store = this.chatStoreMap[chatId];
      store.modelDetail = modelDetail;
      store.aibotInfo = aibotInfo;
      store.docId = docId;
      store.userBotInfo = userBotInfo;
      return store;
    }

    this.chatStoreMap[chatId] = new ChatStore({
      chatId,
      modelDetail,
      aibotInfo,
      docId,
      userBotInfo,
    });
    return this.chatStoreMap[chatId];
  }

  whenChatStoreReady({ chatIds }: { chatIds: number[] }) {
    return when(() => chatIds.every((chatId) => this.chatStoreMap[chatId]?.inited));
  }

  disposeChatStore({ chatId }: { chatId: number }) {
    this.chatStoreMap[chatId]?.dispose();
    delete this.chatStoreMap[chatId];
  }

  disposeAllChatStore() {
    Object.values(this.chatStoreMap).forEach((store) => store.dispose());
    this.chatStoreMap = {};
  }

  chatAll(args: { text?: string; recordId?: number }) {
    Object.values(this.chatStoreMap).forEach((store) =>
      store.chat({ ...args, isMulti: Object.keys(this.chatStoreMap).length > 1 }),
    );
  }

  regenerateChatRecode({ chatId, recordId }: { chatId: number; recordId: number }) {
    this.chatStoreMap[chatId].chat({ recordId });
  }

  abortAll() {
    Object.values(this.chatStoreMap).forEach((store) => store.abort());
  }
}

export default ChatMapStore;
