import { isDev, isTest } from '@/utils/env';
import * as Sentry from '@sentry/nextjs';
import { CaptureConsole } from '@sentry/integrations';

type SentryCommonConfig = Sentry.BrowserOptions | Sentry.NodeOptions | Sentry.EdgeOptions;
const sentryCommonConfig: SentryCommonConfig = {
  enabled: !isDev,
  dsn: process.env.SENTRY_DSN,
  release: process.env.SENTRY_RELEASE,
  environment: isTest ? 'test' : 'production',

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  integrations: [new CaptureConsole({ levels: ['error'] })],
};
export default sentryCommonConfig;
