import type { CSSProperties, HTMLAttributes, DetailedHTMLProps } from 'react';

interface IconProps {
  name: string;
  onClick?: HTMLAttributes<HTMLElement>['onClick'];
  onClickCapture?: HTMLAttributes<HTMLElement>['onClickCapture'];
  size?: number | string;
  fill?: string;
  onMouseEnter?: HTMLAttributes<HTMLElement>['onMouseEnter'];
  onMouseLeave?: HTMLAttributes<HTMLElement>['onMouseLeave'];
  onMouseDown?: HTMLAttributes<HTMLElement>['onMouseDown'];
  onMouseUp?: HTMLAttributes<HTMLElement>['onMouseUp'];
  style?: CSSProperties;
  className?: string;
  title?: string;
}

declare global {
  interface IconParkIconAttributes<T> extends HTMLAttributes<T> {
    name?: string;
    size?: string;
    width?: string;
    height?: string;
    color?: string;
    stroke?: string;
    fill?: string;
    rtl?: string;
    spin?: string;
    class?: string;
  }
  namespace JSX {
    interface IntrinsicElements {
      'iconpark-icon': DetailedHTMLProps<IconParkIconAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

export default function Icon({
  name,
  onClick,
  onClickCapture,
  size,
  fill,
  onMouseEnter,
  onMouseLeave,
  onMouseDown,
  onMouseUp,
  style,
  className,
  title,
}: IconProps) {
  return (
    <iconpark-icon
      name={name}
      onClick={onClick}
      onClickCapture={onClickCapture}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      width={typeof size === 'number' ? size + 'px' : size}
      height={typeof size === 'number' ? size + 'px' : size}
      fill={fill || ''}
      stroke={fill || ''}
      style={style ?? {}}
      className={className}
      class={className}
      title={title}
    />
  );
}
