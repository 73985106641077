// 根据 pageName 产生 TKD 逻辑见 src/coms/head/tkd
// 首页TKD
export const indexTKD = {
  t: '畅问AI丨AI专家助手平台_专家级AI咨询答疑对话平台_集成多款大模型免费用',
  k: '畅问AI,专家顾问,咨询,AI对话,AI机器人,AI聊天,AI问答,智能体,畅问,agent',
  d: '畅问AI是国内领先的AI专家顾问助手平台，官方精选复刻10000+各行各业专家，整理了他们的知识/专业技能/疑难杂症问题，实现AI在线精准咨询。同时支持文心一言、通义千问、月之暗面Kimi、讯飞星火等数十家AI大模型。助力您职场办公与学习效率提升，解决生活、情感、娱乐等多领域不同场景需求。',
};

// 默认TKD
export const defaultTKD = {
  t: '畅问AI丨AI专家助手平台_专家级AI咨询答疑对话平台_集成多款大模型免费用',
  k: '畅问AI,专家顾问,咨询,AI对话,AI机器人,AI聊天,AI问答,智能体,畅问,agent',
  d: '畅问AI是国内领先的AI专家顾问助手平台，官方精选复刻10000+各行各业专家，整理了他们的知识/专业技能/疑难杂症问题，实现AI在线精准咨询。同时支持文心一言、通义千问、月之暗面Kimi、讯飞星火等数十家AI大模型。助力您职场办公与学习效率提升，解决生活、情感、娱乐等多领域不同场景需求。',
};
