import { PriceItem } from '@/coms/recharge';
import { getExtraMap } from '@/utils/buryPoint/extra';
import fetchApi from '@/utils/fetchApi';

export async function getPayPriceList({
  decoratedFetchApi = fetchApi,
  show_page,
}: {
  decoratedFetchApi?: typeof fetchApi;
  show_page: 'vip' | 'num-vip-forever' | 'word';
}) {
  return decoratedFetchApi<{ tab_list: PriceItem[] }>('/pay/pay-vip-price-list', {
    method: 'get',
    params: {
      show_page,
    },
  });
}

export async function startPay({
  decoratedFetchApi = fetchApi,
  coupon_id,
  price_id,
  stats_position,
  model_id,
  bot_id,
}: {
  decoratedFetchApi?: typeof fetchApi;
  coupon_id: number;
  price_id: number;
  stats_position?: number;
  model_id?: number;
  bot_id?: number;
}) {
  return decoratedFetchApi<any>('/pay/auto-pay-vip-build', {
    method: 'post',
    data: {
      coupon_id,
      price_id,
      stats_position: stats_position || getExtraMap()['stats_position'],
      model_id,
      bot_id,
    },
  });
}

export async function loopCheck({
  decoratedFetchApi = fetchApi,
  out_trade_no,
  out_trade_no_wx,
  out_trade_no_zfb,
}: {
  decoratedFetchApi?: typeof fetchApi;
  out_trade_no: string;
  out_trade_no_wx?: string;
  out_trade_no_zfb?: string;
}) {
  return decoratedFetchApi<{
    pay_status: number;
    pay_status_wx?: number;
    pay_status_zfb?: number;
  }>('/pay/pay-vip-status', {
    method: 'post',
    data: {
      out_trade_no,
      out_trade_no_wx,
      out_trade_no_zfb,
    },
  });
}
