import fetchApi from '@/utils/fetchApi';

export interface UserInfo {
  // 以下是从apifox生成的
  ai_num: number; // 剩余ai次数
  ai_word_num: number;
  create_ip: string;
  create_type: number;
  first_referrer_fk: number;
  first_referrer_id: number;
  first_referrer_sign: string;
  hasBindQQ: boolean;
  hasBindWX: boolean;
  head_portrait: string;
  head_portrait_update_time: string;
  authorization: string;
  is_bind_password: number;
  kaiDaAppId: string;
  is_first: number;
  is_vip: number;
  is_banned: number;
  nick_name_update_time: string;
  nickname: string;
  phone: string;
  show_uid: string;
  uid: number;
  user_create_time: number;
  user_type: number;
  username: string;
}

export async function getUserInfo({ decoratedFetchApi = fetchApi } = {}): Promise<UserInfo | null> {
  return decoratedFetchApi<UserInfo>('/user/user-info').catch((e) => {
    if (e.code === 101) {
      return null;
    } else {
      throw e;
    }
  });
}

export async function logout({ decoratedFetchApi = fetchApi } = {}) {
  return decoratedFetchApi<void>('/user/user-logout');
}

export async function getWechatLoginUrl({ decoratedFetchApi = fetchApi } = {}) {
  return decoratedFetchApi<{
    expire_seconds: number;
    expire_time: number;
    qr_src: string;
    scene_str: string;
  }>('/user/wx-service-login-auth').then((res) => {
    res.expire_time = res.expire_seconds * 1000 + Date.now();
    return res;
  });
}

export async function checkWechatLoginStatus({
  decoratedFetchApi = fetchApi,
  scene_str,
}: {
  decoratedFetchApi?: typeof fetchApi;
  scene_str: string;
}) {
  return decoratedFetchApi<boolean>('/user/wx-service-login-check', {
    method: 'post',
    data: { scene_str },
  })
    .then(() => true)
    .catch(() => false);
}

export async function getQQLoginUrl({ decoratedFetchApi = fetchApi } = {}) {
  return decoratedFetchApi<{ url: string }>('/user/qq-auth-login-auth', {
    method: 'post',
    data: { redirect_url: location.origin + '/loginCb4Win' },
  });
}

// 用户微信绑定
export async function getQQBindUrl({ decoratedFetchApi = fetchApi } = {}) {
  return decoratedFetchApi<{ url: string }>('/user/user-qq-login-bind', {
    method: 'post',
    data: { redirect_url: location.origin + '/loginCb4Win' },
  });
}

export async function checkQQLoginStatus({
  decoratedFetchApi = fetchApi,
  code,
}: {
  decoratedFetchApi?: typeof fetchApi;
  code: string;
}) {
  return decoratedFetchApi<boolean>('/user/qq-auth-login-callback', {
    method: 'post',
    data: { code },
  })
    .then(() => true)
    .catch(() => false);
}

export async function sendPhoneCode({
  decoratedFetchApi = fetchApi,
  phone,
}: {
  decoratedFetchApi?: typeof fetchApi;
  phone: string;
}) {
  return decoratedFetchApi<never>('/captcha/send-phone-code', {
    method: 'post',
    data: { phone },
  });
}

export async function phoneCodeLogin({
  decoratedFetchApi = fetchApi,
  phone,
  code,
}: {
  decoratedFetchApi?: typeof fetchApi;
  phone: string;
  code: string;
}) {
  return decoratedFetchApi<{
    accessExpire: number;
    accessToken: string;
    id: number;
    refreshAfter: number;
  }>('/user/phone-code-login', {
    method: 'post',
    data: { phone, code },
  });
}

export async function phonePasswordLogin({
  decoratedFetchApi = fetchApi,
  phone,
  password,
  code,
  isChangingPW = false,
}: {
  decoratedFetchApi?: typeof fetchApi;
  phone: string;
  password: string;
  code?: string;
  isChangingPW?: boolean;
}) {
  const data: Record<string, any> = { phone, password, code };
  if (isChangingPW) {
    data.confirm_password = password;
  }

  return decoratedFetchApi<{
    accessExpire: number;
    accessToken: string;
    id: number;
    refreshAfter: number;
  }>('/user/phone-password-login', {
    method: 'post',
    data,
  });
}

export interface VipList {
  end_days?: number;
  end_time?: string;
  end_time_date?: string;
  is_forever?: number;
  start_time?: string;
  vip_auth_type?: number;
  vip_range?: number;
  vip_type?: number;
}

export async function getUserVipInfo({ decoratedFetchApi = fetchApi } = {}) {
  return decoratedFetchApi<VipList[]>('/user/user-vip-info', {
    method: 'get',
  });
}

// 用户绑定手机号修改
export async function phoneCodeUpdate({
  decoratedFetchApi = fetchApi,
  phone,
  code,
}: {
  decoratedFetchApi?: typeof fetchApi;
  phone: string;
  code: string;
}) {
  return decoratedFetchApi<{
    accessExpire: number;
    accessToken: string;
    id: number;
    refreshAfter: number;
  }>('/user/user-phone-update', {
    method: 'post',
    data: { phone, code },
  });
}

// 用户信息修改
export async function userInfoUpdate({
  decoratedFetchApi = fetchApi,
  code,
  head_portrait,
  nickname,
  password,
}: {
  decoratedFetchApi?: typeof fetchApi;
  code?: string;
  head_portrait?: string;
  nickname?: string;
  password?: string;
}) {
  return decoratedFetchApi('/user/user-basic-info-save', {
    method: 'post',
    data: { code, head_portrait, nickname, password },
  });
}

// 用户微信绑定
export async function checkBindWXStatus({
  decoratedFetchApi = fetchApi,
  scene_str,
}: {
  decoratedFetchApi?: typeof fetchApi;
  scene_str?: string;
}) {
  return decoratedFetchApi('/user/user-bind-wx', {
    method: 'post',
    data: { scene_str },
  });
}

// 用户注销
export async function logoff({
  decoratedFetchApi = fetchApi,
}: {
  decoratedFetchApi?: typeof fetchApi;
}) {
  return decoratedFetchApi('/user/user-logoff', {
    method: 'post',
  });
}

export async function getSumInfo({ decoratedFetchApi = fetchApi } = {}) {
  return decoratedFetchApi<{ bot_num: number; model_num: number }>('/sum/sum-info');
}

export async function copyAIChat({ id = 0, decoratedFetchApi = fetchApi } = {}) {
  return decoratedFetchApi('/ai-chat/chat-history-copy', {
    method: 'post',
    data: { history_id: id },
  });
}

export async function saveUserSetting({ setting = '', decoratedFetchApi = fetchApi } = {}) {
  return decoratedFetchApi('/user-setting/save', {
    method: 'post',
    data: { custom_config: setting },
  });
}

export async function getUserSetting({ decoratedFetchApi = fetchApi } = {}) {
  return decoratedFetchApi('/user-setting/info');
}
