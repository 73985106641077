import { isDev, isProd, isTest } from '@/utils/env';

export function getApiRootUrl() {
  return isProd ? 'https://api.changwenai.com' : 'https://api.changwenai.com';
}

export function getMiddleApiRootUrl() {
  return isProd ? 'https://api.aikaida.com' : '/api';
}

export function getRootUrl() {
  return isProd
    ? 'https://changwenai.com/'
    : isTest
    ? 'https://changwenai.com/'
    : 'http://localhost:3600/';
}

export const iconparkUrl =
  'https://lf1-cdn-tos.bytegoofy.com/obj/iconpark/icons_28912_115.6900acc66a6e7c0239e857771b30cb46.es5.js';

// 用户协议
export const userAgreementUrl = '/user-agreement.html';

// 字数充值协议
export const wordRechargeAgreementUrl = '/word-recharge-service-agreement.html';

// 隐私政策
export const privacyPolicyUrl = '/privacy-policy.html';

// 儿童个人信息保护
export const childrenInfoProtectionUrl = '/ChildrenInfoProtection.pdf';

// 关于我们
export const aboutusUrl = '/aboutus';

// 笔灵
export const bilingUrl = 'https://ibiling.cn/';

// 树图
export const shutuUrl = 'https://shutu.cn/';

// 心动
export const xindongUrl = 'https://cv.ibiling.cn/?from=biling';

// 沪ICP备2023010975号-2
export const beiangovUrl = 'https://beian.miit.gov.cn/#/Integrated/index';

// 猴子音悦
export const houzi8Url = 'https://houzi8.com/';

// 摄图网
export const shetuUrl = 'https://699pic.com/';

// 虎课网
export const hukeUrl = 'https://huke88.com/';

// 畅问AI
export const botvipUrl = 'https://changwenai.com/';

// 潮点视频
export const shipin520Url = 'https://shipin520.com/';

export const shanghaiIndustryAndCommerceUrl =
  'https://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020230518153913000010542271-SAIC_SHOW_310000-20231107145313138379&signData=MEQCIG4NauqrLsqzxPC+nc4kx+hJzTdr1IjcD/CdqSh3KIV5AiBvZc/pbNhW1nmJZcJ9YfSTyQOy8jBii834enQTda20vQ==';

// 图怪兽
export const psUrl = 'https://818ps.com/';

// 发票申请
export const invoiceUrl =
  'https://l0b4deki7ad.feishu.cn/share/base/form/shrcnErqbpUQzNrId5U4wg5Djtb';
