import STYLE from './index.module.less';
import { message, modal } from '@/configs/theme';
import { useCountDown, useRafInterval } from 'ahooks';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import Option from './option';
import { QRCode } from 'antd';
import Icon from '@/coms/icon';
import { PriceItem } from '@/coms/recharge';
import { getPayPriceList, loopCheck, startPay } from '@/services/recharge';
import CloseBtn from '@/coms/utils/closeBtn';
import userInfoStore from '@/stores/userInfo';
import { getExtraMap } from '@/utils/buryPoint/extra';

export function getDate() {
  // 获取当前日期
  const currentDate = new Date();

  // 将时间设定为当天的 23:59:59
  currentDate.setHours(23, 59, 59, 0);

  // 获取年、月、日、小时、分钟和秒
  const year = currentDate.getFullYear();
  const month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // 月份从 0 开始，所以要加 1，并确保两位数格式
  const day = ('0' + currentDate.getDate()).slice(-2); // 确保两位数格式
  const hours = ('0' + currentDate.getHours()).slice(-2); // 确保两位数格式
  const minutes = ('0' + currentDate.getMinutes()).slice(-2); // 确保两位数格式
  const seconds = ('0' + currentDate.getSeconds()).slice(-2); // 确保两位数格式

  // 格式化日期时间
  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDateTime;
}

let time = 1000;
let activityModalHelper: any;

function ActivityModal() {
  const millisecondsRef = useRef<HTMLDivElement>(null);

  const [, formattedRes] = useCountDown({ targetDate: getDate() });
  const { hours, minutes, seconds, milliseconds } = formattedRes;

  const [priceList, setPriceList] = useState<PriceItem[]>([]);
  const [currentPrice, setCurrentPrice] = useState<PriceItem>({ id: 0, price: 0 });
  const [pay_url, setPayUrl] = useState('');

  const timer = useRef<any>(null);

  useEffect(() => {
    getPriceList();
    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    if (currentPrice?.id) {
      timer.current && clearTimeout(timer.current);
      startPay({
        price_id: currentPrice?.id,
        coupon_id: 0,
        model_id: getExtraMap().model_id,
        bot_id: getExtraMap().bot_id,
      }).then((data) => {
        const { code_url, out_trade_no } = data;
        setPayUrl(code_url);
        pollingCheckPay(out_trade_no);
      });
    }
  }, [currentPrice?.id]);

  const getPriceList = () => {
    getPayPriceList({ show_page: 'num-vip-forever' }).then((data) => {
      const { tab_list } = data;
      if (tab_list.length) {
        const list = tab_list[0]?.list || [];

        const priceList = [
          { name: '终身会员', annotation: '赠送1500次对话' },
          { name: '1年会员', annotation: '赠送800次对话' },
        ].map(({ name, annotation }) => {
          return {
            ...list.find((i: any) => i.name === name),
            name,
            annotation,
          };
        });

        setPriceList(priceList);
        setCurrentPrice(priceList[0]);
      }
    });
  };

  // 轮询检查支付结果
  const pollingCheckPay = (out_trade_no: string) => {
    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      loopCheck({ out_trade_no })
        .then((data) => {
          if (data.pay_status) {
            onSuccess();
            message.success('支付成功');
          } else {
            pollingCheckPay(out_trade_no);
          }
        })
        .catch((e) => console.log(e));
    }, 2000);
  };

  const onSuccess = () => {
    userInfoStore.updateUserInfo();
    userInfoStore.updateRechargeType();
    onClose();
  };

  const onClose = () => {
    activityModalHelper?.destroy();
    activityModalHelper = undefined;
  };

  useEffect(() => {
    time = 1000;
  }, [milliseconds]);

  useRafInterval(() => {
    if (time > 0) {
      if (time < 100) {
        time = 1000;
      } else {
        const diff = time - 0;
        time = Math.floor(time - diff / (1000 / 40));
        millisecondsRef.current!.innerText = time.toString();
      }
    }
  }, 1000 / 40);

  return (
    <div className={STYLE.root}>
      <Image className={STYLE.img} src={require('./w 2.png')} alt="blur bg" />
      <CloseBtn onClick={onClose} className={'black'} />
      <div className={STYLE.countdown}>
        <div className={STYLE.text}>活动倒计时：</div>
        <div className={STYLE.hour}>
          <div>{hours}</div>
          <div>时</div>
        </div>
        <div className={STYLE.minute}>
          <div>{minutes}</div>
          <div>分</div>
        </div>
        <div className={STYLE.second}>
          <div>{seconds}</div>
          <div>秒</div>
        </div>
        <div className={STYLE.millisecond}>
          <div ref={millisecondsRef}></div>
        </div>
      </div>
      <div className={STYLE.recharge}>
        <div className={STYLE.top}>
          {priceList.map((item, index) => {
            return (
              <Option
                selected={currentPrice.id === item.id}
                recommend={index === 0}
                {...item}
                onClick={() => {
                  setCurrentPrice(item);
                }}
              />
            );
          })}
        </div>
        <div className={STYLE.bottom}>
          <div className={STYLE.bottom_top}>
            <span>需支付:</span>
            <div className={STYLE.price}>
              <div className={STYLE.sign}>{'¥'}</div>
              {currentPrice.price}
            </div>
          </div>
          <QRCode className={STYLE.qrcode} value={pay_url || '-'} size={126} />
          <div className={STYLE.payment_method}>
            <Icon name={'weixin-anjp338c'} size={16} style={{ marginRight: '6px' }} />
            <Icon name={'zhifubao'} size={16} style={{ marginRight: '6px' }} />
            扫码支付
          </div>
        </div>
      </div>
    </div>
  );
}

export function commonShowActivityModal() {
  activityModalHelper = modal.info({
    content: <ActivityModal />,
    closeIcon: false,
    footer: null,
    icon: null,
    centered: true,
    width: 750,
    className: 'clearAntdModalContentPadding hiddenAntdModalContentOverflow',
    maskClosable: true,
    maskStyle: { backgroundColor: 'rgba(0, 0, 0, 0.2)' },
  });
}
