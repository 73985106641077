import { getTodayReferrer } from '@/utils/buryPoint';
import { capitalizeFirstLetter, getUrlParam } from '@/utils/common';
import { getIsClient } from '@/utils/env';
import { isUndefined } from 'lodash-es';
import { autorun, makeAutoObservable, reaction, when } from 'mobx';
import userInfoStore from './userInfo';

class CommonStore {
  nextReady: boolean = false;

  modelView: number = 0;

  leftModelListOpenState: boolean | undefined;

  historyListOpenState: boolean | undefined;

  todayReferrerId: number | undefined;

  from: string | undefined;

  AINumberLabelOpen: boolean = false;

  enjoyLabelOpen: boolean = false;

  shareList: number[] = [];

  shareState: boolean = false;

  shareListForMulti: { modelId: number; chatId: number }[] = [];

  shareStateForMulti: boolean = false;

  multiSelectorList: number[] = [];

  leftMenuOpenState: boolean | undefined;

  aibotHelperExportState: boolean = false;

  myAIBotModalShow: boolean = false;

  scrollTargetClassifyId: number | string | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.init();
  }

  private *init() {
    if (getIsClient()) {
      // 解决next水合报错问题
      yield this.whenNextReady();

      this.getTodayReferrerId();

      this.from = getUrlParam('from');

      this.toggleAINumberLabelOpen(!!userInfoStore.isFirst);

      reaction(
        () => this.todayReferrerId,
        (cur, prev) => {
          if (cur === prev) return;
          if (this.from === 'aizj') {
            // Router.push('/model/multi');
            this.toggleLeftModelListOpenState(false);
            this.toggleHistoryListOpenState(false);
            this.toggleLeftMenuOpenState(false);
          }
          this.handleLocalStorageState('leftModelListOpenState');
          this.handleLocalStorageState('historyListOpenState');
          this.handleLocalStorageState('leftMenuOpenState');
        },
      );
    }
  }

  // 用于解决next水合报错问题
  updateNextReady(v: boolean) {
    this.nextReady = v;
  }

  // 用于解决next水合报错问题
  whenNextReady() {
    return when(() => this.nextReady);
  }

  handleLocalStorageState(key: keyof CommonStore, defaultState = true) {
    autorun(() => {
      if (isUndefined(this[key])) {
        const v = localStorage.getItem(key) as string | null;
        const funName = `toggle${capitalizeFirstLetter(key)}` as keyof CommonStore;
        const f = this[funName] as (v?: boolean) => void;
        f(v ? JSON.parse(v) : defaultState);
      }
      localStorage.setItem(key, JSON.stringify(this[key]));
    });
  }

  handleLocalStorageValue(key: keyof CommonStore, defaultValue: any) {
    autorun(() => {
      if (isUndefined(this[key])) {
        const v = localStorage.getItem(key) as string | null;
        const funName = `update${capitalizeFirstLetter(key)}` as keyof CommonStore;
        const f = this[funName] as (v?: any) => void;
        f(v ? JSON.parse(v) || defaultValue : defaultValue);
      }
      localStorage.setItem(key, JSON.stringify(this[key]));
    });
  }

  // 获取用户渠道来源
  getTodayReferrerId() {
    getTodayReferrer().then((cookies) => {
      this.updateTodayReferrerId(+cookies?.today_referrer_id);
    });
  }

  updateModelView(view: number) {
    this.modelView = view;
  }

  toggleLeftModelListOpenState(leftModelListOpenState?: boolean) {
    this.leftModelListOpenState = leftModelListOpenState ?? !this.leftModelListOpenState;
  }

  toggleHistoryListOpenState(historyListOpenState?: boolean) {
    this.historyListOpenState = historyListOpenState ?? !this.historyListOpenState;
  }

  updateTodayReferrerId(id: number) {
    this.todayReferrerId = id;
  }

  toggleAINumberLabelOpen(AINumberLabelOpen?: boolean) {
    this.AINumberLabelOpen = AINumberLabelOpen ?? !this.AINumberLabelOpen;
  }

  toggleEnjoyLabelOpen(enjoyLabelOpen?: boolean) {
    this.enjoyLabelOpen = enjoyLabelOpen ?? !this.enjoyLabelOpen;
  }

  updateShareList(shareList: number[]) {
    this.shareList = shareList;
  }

  toggleShareState(shareState?: boolean) {
    this.shareState = shareState ?? !this.shareState;
  }

  updateShareListForMulti(shareListForMulti: { modelId: number; chatId: number }[]) {
    this.shareListForMulti = shareListForMulti;
  }

  toggleShareStateForMulti(shareStateForMulti?: boolean) {
    this.shareStateForMulti = shareStateForMulti ?? !this.shareStateForMulti;
  }

  updateMultiSelectorList(multiSelectorList: number[]) {
    this.multiSelectorList = multiSelectorList;
  }

  toggleLeftMenuOpenState(leftMenuOpenState?: boolean) {
    this.leftMenuOpenState = leftMenuOpenState ?? !this.leftMenuOpenState;
  }

  toggleAIBotHelperExportState(aibotHelperExportState?: boolean) {
    this.aibotHelperExportState = aibotHelperExportState ?? !this.aibotHelperExportState;
  }

  toggleMyAIBotModalShow(myAIBotModalShow: boolean) {
    this.myAIBotModalShow = myAIBotModalShow;
  }

  updateScrollTargetClassifyId(scrollTargetClassifyId: number | string | null) {
    this.scrollTargetClassifyId = scrollTargetClassifyId;
  }
}

const commonStore = new CommonStore();
export default commonStore;

// for debug
if (getIsClient()) {
  (window as any).__commonStore = commonStore;
}
