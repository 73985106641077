import loadScript from './loadScript';
import { jweixin } from './url';
import UAParser from 'ua-parser-js';

export const isDev = process.env.NODE_ENV === 'development';
export const isProd = process.env.NODE_ENV === 'production' && !process.env.NEXT_PUBLIC_IS_TEST;
export const isTest = !!process.env.NEXT_PUBLIC_IS_TEST;

export function getIsServer() {
  return typeof window === 'undefined';
}
// 是否在浏览器环境
export function getIsClient() {
  return !getIsServer();
}

export const getIsWechat = (ua = navigator.userAgent) => /micromessenger/i.test(ua);
// @ts-ignore
export const getWXMiNiProgram = (): Promise<boolean> => {
  if (!getIsWechat()) return Promise.resolve(false);
  return new Promise((resolve, reject) => {
    loadScript('wx', jweixin)
      .then((res) => {
        (window as any).wx?.miniProgram.getEnv((res: any) => {
          if (res.miniprogram) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      })
      .catch((err) => {
        resolve(false);
      });
  });
};

export const getIsApp = (ua = navigator.userAgent) => /shutu_app/i.test(ua);
export const getAppVersion = (ua = navigator.userAgent) => {
  const match = ua.match(/shutu_app\/(\d+\.\d+\.\d+)/i);
  return match ? match[1] : '';
};

export function getIsInIosApp(ua = navigator.userAgent) {
  if (getIsApp(ua)) {
    const { name } = new UAParser(ua).getOS();
    return name === 'iOS' || name === 'Mac OS';
  } else {
    return false;
  }
}

export function getIsInAndroidApp(ua = navigator.userAgent) {
  if (getIsApp(ua)) {
    const { name } = new UAParser(ua).getOS();
    return name === 'Android';
  } else {
    return false;
  }
}

/**
 * 比较版本号
 * 如果 v2 > v1 返回 true
 */
export function compareVersion(v1: string, v2: string): boolean {
  const v1Arr = v1.split('.');
  const v2Arr = v2.split('.');
  const len = Math.max(v1Arr.length, v2Arr.length);
  for (let i = 0; i < len; i++) {
    const num1 = parseInt(v1Arr[i] || '0', 10);
    const num2 = parseInt(v2Arr[i] || '0', 10);
    if (num1 > num2) {
      return false;
    } else if (num1 < num2) {
      return true;
    }
  }
  return false;
}

export function getAppChannelSign(ua = navigator.userAgent) {
  const match = ua.match(/channelSign\/(\S+)/i);
  return match ? match[1] : '';
}

// 获取app环境下，用户已安装的app列表。
// 具体需查看app侧实现
export function getInstalledApps(ua = navigator.userAgent) {
  const match = ua.match(/installedApps\/(\w+)/i);
  return match ? match[1].split(',') : [];
}
