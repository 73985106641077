import fetchApi from '@/utils/fetchApi';

export async function updateAIUserCollect({
  decoratedFetchApi = fetchApi,
  itemType,
  id,
  isCollect,
}: {
  decoratedFetchApi?: typeof fetchApi;
  itemType?: 1 | 2; // 1-模型 2-j机器人
  id?: number;
  isCollect: boolean;
}) {
  return decoratedFetchApi('/collect/ai-user-collect-update', {
    method: 'post',
    data: {
      item_type: itemType,
      model_id: itemType === 1 ? id : undefined,
      bot_id: itemType === 2 ? id : undefined,
      status: isCollect ? 1 : -1,
    },
  });
}

export interface Collect {
  bot_id?: number;
  bot_type_id?: number;
  brief_introduction?: string;
  head_portrait?: string;
  id?: number;
  item_type?: 1 | 2; // 1-模型 2-j机器人
  model_id?: number;
  name?: string;
  use_num?: number;
  vip_type?: number;
}
// 机器人评价创建接口
export async function createAiBotScore({
  type,
  botId,
  score,
  comment,
  decoratedFetchApi = fetchApi,
}: {
  botId?: string;
  type: number;
  score: number;
  comment: string;
  decoratedFetchApi?: typeof fetchApi;
}) {
  return decoratedFetchApi('/ai-bot/ai-bot-score-create', {
    method: 'post',
    data: {
      type,
      bot_id: botId,
      score,
      comment,
    },
  });
}
// 机器人评价更新接口
export async function updateAiBotScore({
  type,
  uid,
  botId,
  score,
  comment,
  decoratedFetchApi = fetchApi,
}: {
  type: number;
  botId?: string;
  uid: number;
  score: number;
  comment: string;
  decoratedFetchApi?: typeof fetchApi;
}) {
  return decoratedFetchApi('/ai-bot/ai-bot-score-update', {
    method: 'post',
    data: {
      type,
      uid,
      bot_id: botId,
      score,
      comment,
    },
  });
}
// 机器人评价查询接口
export async function detailsAiBotScore({
  type,
  botId,
  decoratedFetchApi = fetchApi,
}: {
  botId?: string;
  type: number;
  decoratedFetchApi?: typeof fetchApi;
}) {
  return decoratedFetchApi('/ai-bot/ai-bot-score-list', {
    // method: 'get',
    params: {
      type,
      bot_id: botId,
    },
  });
}

export async function getAIUserCollectList({
  decoratedFetchApi = fetchApi,
  page,
  pageSize,
}: {
  decoratedFetchApi?: typeof fetchApi;
  page?: number;
  pageSize?: number;
}) {
  return decoratedFetchApi<{
    list: Collect[];
    count: number;
  }>('/collect/ai-user-collect-list', {
    params: {
      page,
      page_size: pageSize,
    },
  });
}

export interface CheckList {
  botId?: number;
  itemType?: 1 | 2; // 1-模型 2-j机器人
  modelId?: number;
}

export async function checkAIUserCollect({
  decoratedFetchApi = fetchApi,
  checkList = [],
}: {
  decoratedFetchApi?: typeof fetchApi;
  checkList: CheckList[];
}) {
  const check_list: {
    bot_id?: number;
    item_type?: 1 | 2; // 1-模型 2-j机器人
    model_id?: number;
  }[] = checkList.map((item) => {
    const { botId, itemType, modelId } = item;
    return {
      bot_id: botId,
      item_type: itemType,
      model_id: modelId,
    };
  });
  return decoratedFetchApi('/collect/ai-user-collect-check', {
    method: 'post',
    data: {
      check_list,
    },
  });
}
