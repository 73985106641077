import { getIsClient } from '@/utils/env';
import { makeAutoObservable, when } from 'mobx';
import { checkAIUserCollect } from '@/services/collect';
import userInfoStore from './userInfo';

class CollectStore {
  modelIdList: number[] = [];
  aibotIdList: number[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  updateModelIdList(modelIdList: number[]) {
    this.modelIdList = modelIdList;
  }

  updateAIBotIdList(aibotIdList: number[]) {
    this.aibotIdList = aibotIdList;
  }

  *checkModelCollect(ids: number[]) {
    if (!ids.length) return;
    yield when(() => userInfoStore.isLogged === 'logged');
    const { list } = yield checkAIUserCollect({
      checkList: ids.map((modelId) => ({ modelId, itemType: 1, botId: 0 })),
    });
    for (const id of ids) {
      if (list.some((i: any) => i.model_id === id) && !this.modelIdList.includes(id)) {
        this.modelIdList.push(id);
      } else if (list.every((i: any) => i.model_id !== id) && this.modelIdList.includes(id)) {
        const index = this.modelIdList.indexOf(id);
        this.modelIdList.splice(index, 1);
      }
    }
  }

  *checkAIBotCollect(ids: number[]) {
    if (!ids.length) return;
    yield when(() => userInfoStore.isLogged === 'logged');
    const { list } = yield checkAIUserCollect({
      checkList: ids.map((botId) => ({ botId, itemType: 2, modelId: 0 })),
    });
    for (const id of ids) {
      if (list.some((i: any) => i.bot_id === id) && !this.aibotIdList.includes(id)) {
        this.aibotIdList.push(id);
      } else if (list.every((i: any) => i.bot_id !== id) && this.aibotIdList.includes(id)) {
        const index = this.aibotIdList.indexOf(id);
        this.aibotIdList.splice(index, 1);
      }
    }
  }
}

const collectStore = new CollectStore();
export default collectStore;

// for debug
if (getIsClient()) {
  (window as any).__collectStore = collectStore;
}
