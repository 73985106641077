import { privacyPolicyUrl, userAgreementUrl } from '@/configs/url';
import { Button, Checkbox, Form, FormInstance, Input, Space, CheckboxProps } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import STYLE from './index.module.less';
import { Dispatch, SetStateAction, useState } from 'react';
import { useCountDown, useRequest } from 'ahooks';
import { sendPhoneCode } from '@/services/user';
import clx from 'classnames';

export type Page = 'login' | 'signUp' | 'changePW';

export interface PageProps {
  setCurPage: Dispatch<SetStateAction<Page>>;
  onSuccess: () => void;
  className?: string;
  phone?: string;
  setPhone?: Dispatch<SetStateAction<string>>;
}

export interface LoginFormField {
  phone: string;
  code: string;
  password: string;
  // agreement: boolean;
  // confirmPassword: string;
}

export function AgreementCheck({ className, ...restProps }: CheckboxProps) {
  return (
    <Checkbox {...restProps} className={clx(STYLE.agreementCheck, className)}>
      登录即同意《
      <a target="_blank" href={userAgreementUrl} className="currentColorA">
        用户协议
      </a>
      》《
      <a target="_blank" href={privacyPolicyUrl} className="currentColorA">
        隐私政策
      </a>
      》
    </Checkbox>
  );
}

export function PhoneAndCodeFormItem({
  form,
}: {
  form: FormInstance<Pick<LoginFormField, 'phone' | 'code'>>;
}) {
  const { runAsync: sendCode, loading: sendCodeLoading } = useRequest(sendPhoneCode, {
    manual: true,
  });
  const [sendCodeCDTargetDate, setSendCodeCDTargetDate] = useState(() => Date.now());
  const [sendCodeCD] = useCountDown({ targetDate: sendCodeCDTargetDate });

  return (
    <>
      <Form.Item<LoginFormField> name="phone" rules={[{ required: true, message: '请输入手机号' }]}>
        <Input placeholder="请输入手机号" type="tel" autoComplete="tel-national" />
      </Form.Item>

      <Form.Item>
        <Space size={14} className={STYLE.phoneAndCodeFormItemSpace}>
          <Form.Item<LoginFormField>
            noStyle
            name="code"
            rules={[{ required: true, message: '请输入验证码' }]}
          >
            <Input
              placeholder="请输入验证码"
              inputMode="numeric"
              maxLength={6}
              autoComplete="one-time-code"
            />
          </Form.Item>
          <Button
            type="primary"
            ghost
            loading={sendCodeLoading}
            disabled={sendCodeCD !== 0}
            onClick={() => {
              form
                .validateFields(['phone'])
                .then(({ phone }) => {
                  sendCode({ phone })
                    .then(() => {
                      setSendCodeCDTargetDate(Date.now() + 60000);
                    })
                    .catch((e) => {
                      // console.error(e.msg);
                      form.setFields([{ name: 'phone', errors: [e.msg] }]);
                    });
                })
                .catch(console.log);
            }}
          >
            {sendCodeCD === 0 ? '获取验证码' : `${Math.ceil(sendCodeCD / 1000)}秒后重试`}
          </Button>
        </Space>
      </Form.Item>
    </>
  );
}
