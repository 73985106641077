import { observer } from 'mobx-react';
import STYLE from './index.module.less';
import AIHeadPortrait from '@/coms/utils/aiHeadPortrait';
import { ChatGroup, updateChatGroup } from '@/services/chat';
import modelStore from '@/stores/model';
import { useRouter } from 'next/router';
import Icon from '@/coms/icon';
import { Popconfirm } from 'antd';
import clx from 'classnames';

function GroupItem({
  data,
  itemContentMaxWidth,
  reload,
}: {
  data: ChatGroup;
  itemContentMaxWidth?: number;
  reload: () => void;
}) {
  const router = useRouter();
  const { list: modelList } = modelStore;

  const modelId = modelList?.find((item) => item.sign === router.query.modelSign)?.id;
  const aibotId = +(router.query.aibotId || '');
  const sessionId = router.query.sessionId || '';

  const isModel = !!data?.model_id;
  const isUserBot = !!data?.user_bot_id;

  const selected =
    sessionId &&
    sessionId === data.hex &&
    (isModel
      ? modelId && data.model_id === modelId
      : isUserBot
      ? aibotId && data.user_bot_id === aibotId
      : aibotId && data.bot_id === aibotId);

  const onClickGroup = () => {
    if (isModel) {
      const model = modelList?.find((item) => item.id === data?.model_id);
      router.push(`/model/${model?.sign}/${data.hex}`);
    } else if (isUserBot) {
      router.push(`/aibot/u/${data.user_bot_id}/${data.hex}`);
    } else {
      router.push(`/aibot/${data.bot_id}/${data.hex}`);
    }
  };

  const onDelCurrentGroup = async () => {
    if (!data?.hex) return;
    await updateChatGroup({ groupHex: data?.hex, toDel: true });
    reload();
  };

  return (
    <div
      className={clx(STYLE.root, { [STYLE.selected]: selected })}
      onClick={onClickGroup}
      style={{ maxWidth: itemContentMaxWidth }}
    >
      <div className={STYLE.left}>
        <AIHeadPortrait src={data?.head_portrait || ''} size={36} isVip={data?.vip_type === 1} />
      </div>
      <div className={STYLE.middle}>
        <span>{data?.name}</span>
        <span>{data?.title}</span>
        <span>{data?.last_reply_content}</span>
      </div>
      <div className={STYLE.right}>
        <Popconfirm
          title={null}
          icon={null}
          arrow
          autoAdjustOverflow
          description="删除后无法恢复，是否继续删除？"
          onConfirm={() => onDelCurrentGroup()}
          onPopupClick={(e) => e.stopPropagation()}
          placement="topRight"
          okText="确认"
          cancelText="取消"
        >
          <Icon
            name="shanchu"
            size={20}
            fill="rgba(158, 158, 158, 1)"
            onClick={(e) => e.stopPropagation()}
          />
        </Popconfirm>
      </div>
    </div>
  );
}

export default observer(GroupItem);
