import { AgreementCheck, PhoneAndCodeFormItem } from '@/coms/loginModal/common';
import STYLE from './index.module.less';
import { Button, Form } from 'antd';
import Icon from '@/coms/icon';
import { useRequest } from 'ahooks';
import { phoneCodeUpdate } from '@/services/user';
import { message } from '@/configs/theme';

function BindPhone({ onSuccess = () => {}, onClose = () => {} }) {
  const [form] = Form.useForm();

  const { runAsync: runPhoneCodeUpdate, loading: phoneCodeUpdateLoading } = useRequest(
    phoneCodeUpdate,
    { manual: true },
  );

  return (
    <div className={STYLE.root}>
      <Icon name={'guanbi'} size={20} onClick={() => onClose()} />
      <div className={STYLE.top}>
        <div className={STYLE.title}>绑定手机号</div>
        <div className={STYLE.title2}>绑定手机号后，立即解锁AI对话功能</div>
      </div>
      <Form
        className={STYLE.form}
        form={form}
        onFinish={({ phone, code }) => {
          runPhoneCodeUpdate({ phone, code })
            .then(() => {
              message.success('手机号绑定成功');
              onSuccess();
            })
            .catch((e) => {
              if (e.code === 102) {
                form.setFields([{ name: 'phone', errors: [e.msg] }]);
              } else {
                message.error(e.msg);
              }
            });
        }}
      >
        <PhoneAndCodeFormItem form={form} />
        <AgreementCheck defaultChecked />
        <Form.Item rootClassName={STYLE.bottom}>
          <Button
            rootClassName={STYLE.btn}
            loading={phoneCodeUpdateLoading}
            type="primary"
            htmlType="submit"
          >
            确认
          </Button>
          <Button rootClassName={STYLE.btn} type="link" htmlType="button" onClick={() => onClose()}>
            稍后设置
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default BindPhone;
