const eventTypes: { [key: string]: string }[] = [
  {
    site_type: 'botvip_web',
    memo: '访问',
    request_type: '1',
    request_code: '2000',
    block_group: '',
    block: '',
    position: '',
    option: '',
    operate: '',
  },
  {
    site_type: 'botvip_web',
    memo: '模型选择-点击',
    request_type: '2',
    request_code: '2000',
    block_group: '100',
    block: '1000',
    position: '1',
    option: '',
    operate: '',
  },
  {
    site_type: 'botvip_web',
    memo: '多模型对话-点击',
    request_type: '2',
    request_code: '2000',
    block_group: '100',
    block: '1000',
    position: '2',
    option: '',
    operate: '2',
  },
  {
    site_type: 'botvip_web',
    memo: '复制-点击',
    request_type: '2',
    request_code: '2000',
    block_group: '100',
    block: '1000',
    position: '3',
    option: '',
    operate: '',
  },
  {
    site_type: 'botvip_web',
    memo: 'AI字数充值弹窗',
    request_type: '4',
    request_code: '1004',
    block_group: '',
    block: '1',
    position: '',
    option: '',
    operate: '',
  },
  {
    site_type: 'botvip_web',
    memo: '会员充值弹窗',
    request_type: '4',
    request_code: '1004',
    block_group: '',
    block: '2',
    position: '',
    option: '',
    operate: '',
  },
  {
    site_type: 'botvip_web',
    memo: '单模型对话-使用',
    request_type: '4',
    request_code: '2000',
    block_group: '100',
    block: '1000',
    position: '1',
    option: '',
    operate: '1',
  },
  // {
  //   site_type: 'botvip_web',
  //   memo: 'AI机器人-使用',
  //   request_type: '4',
  //   request_code: '2000',
  //   block_group: '100',
  //   block: '1000',
  //   position: '1',
  //   option: '',
  //   operate: '3',
  // },
  {
    site_type: 'botvip_web',
    memo: '多模型对话-使用',
    request_type: '4',
    request_code: '2000',
    block_group: '100',
    block: '1000',
    position: '1',
    option: '',
    operate: '2',
  },
  {
    site_type: 'botvip_web',
    memo: '今日首次访问-可见',
    request_type: '0',
    request_code: '0',
    block_group: '0',
    block: '88',
    position: '0',
    option: '0',
    operate: '0',
  },
  {
    site_type: 'botvip_web',
    memo: '字数限制弹窗',
    request_type: '4',
    request_code: '1002',
    block_group: '0',
    block: '2',
    position: '0',
    option: '0',
    operate: '0',
  },
  {
    site_type: 'botvip_web',
    memo: '会员限制弹窗',
    request_type: '4',
    request_code: '1002',
    block_group: '0',
    block: '1',
    position: '0',
    option: '0',
    operate: '0',
  },
  {
    site_type: 'botvip_web',
    memo: '首页-tab点击-机器人广场',
    request_type: '2',
    request_code: '2000',
    block_group: '100',
    block: '1080',
    position: '3',
    option: '0',
    operate: '0',
  },
  {
    site_type: 'botvip_web',
    memo: '首页-tab点击-多模型pk',
    request_type: '2',
    request_code: '2000',
    block_group: '100',
    block: '1080',
    position: '2',
    option: '0',
    operate: '0',
  },
  {
    site_type: 'botvip_web',
    memo: '首页-tab点击-单模型对话',
    request_type: '2',
    request_code: '2000',
    block_group: '100',
    block: '1080',
    position: '1',
    option: '0',
    operate: '0',
  },
  {
    site_type: 'botvip_web',
    memo: '首页-发起提问',
    request_type: '4',
    request_code: '2000',
    block_group: '100',
    block: '1020',
    position: '1',
    option: '0',
    operate: '0',
  },
  {
    site_type: 'botvip_web',
    memo: 'AI机器人-对话',
    request_type: '4',
    request_code: '2000',
    block_group: '100',
    block: '1000',
    position: '1',
    option: '0',
    operate: '3',
  },
  {
    site_type: 'botvip_web',
    memo: 'ai机器人-点击',
    request_type: '2',
    request_code: '1020',
    block_group: '100',
    block: '1000',
    position: '1',
    option: '0',
    operate: '0',
  },
  {
    site_type: 'botvip_web',
    memo: 'ai机器人-使用',
    request_type: '2',
    request_code: '1020',
    block_group: '100',
    block: '1000',
    position: '2',
    option: '0',
    operate: '0',
  },
  {
    site_type: 'botvip_web',
    memo: 'ai机器人-复制按钮-点击',
    request_type: '2',
    request_code: '1020',
    block_group: '100',
    block: '1000',
    position: '3',
    option: '0',
    operate: '0',
  },
  {
    site_type: 'botvip_web',
    memo: 'ai机器人-导出按钮-点击',
    request_type: '2',
    request_code: '1020',
    block_group: '100',
    block: '1000',
    position: '4',
    option: '0',
    operate: '0',
  },
  {
    site_type: 'botvip_web',
    memo: 'ai机器人-分享按钮-点击',
    request_type: '2',
    request_code: '1020',
    block_group: '100',
    block: '1000',
    position: '5',
    option: '0',
    operate: '0',
  },
  {
    site_type: 'botvip_web',
    memo: 'ai机器人-收藏按钮-点击',
    request_type: '2',
    request_code: '1020',
    block_group: '100',
    block: '1000',
    position: '6',
    option: '0',
    operate: '0',
  },
  {
    site_type: 'botvip_web',
    memo: '单模型对话-点击',
    request_type: '2',
    request_code: '2000',
    block_group: '100',
    block: '1000',
    position: '2',
    option: '0',
    operate: '1',
  },
  {
    site_type: 'botvip_web',
    memo: '单模型对话-对话',
    request_type: '4',
    request_code: '2000',
    block_group: '100',
    block: '1000',
    position: '1',
    option: '0',
    operate: '1',
  },
  {
    site_type: 'botvip_web',
    memo: '单模型-使用',
    request_type: '2',
    request_code: '1020',
    block_group: '100',
    block: '1000',
    position: '7',
    option: '0',
    operate: '0',
  },
  {
    site_type: 'botvip_web',
    memo: '单模型-复制按钮-点击',
    request_type: '2',
    request_code: '1020',
    block_group: '100',
    block: '1000',
    position: '8',
    option: '0',
    operate: '0',
  },
  {
    site_type: 'botvip_web',
    memo: '单模型-导出按钮-点击',
    request_type: '2',
    request_code: '1020',
    block_group: '100',
    block: '1000',
    position: '9',
    option: '0',
    operate: '0',
  },
  {
    site_type: 'botvip_web',
    memo: '单模型-分享按钮-点击',
    request_type: '2',
    request_code: '1020',
    block_group: '100',
    block: '1000',
    position: '10',
    option: '0',
    operate: '0',
  },
  {
    site_type: 'botvip_web',
    memo: '单模型-收藏按钮-点击',
    request_type: '2',
    request_code: '1020',
    block_group: '100',
    block: '1000',
    position: '11',
    option: '0',
    operate: '0',
  },
  {
    site_type: 'botvip_web',
    memo: '首页顶部搜索框-搜索',
    request_type: '2',
    request_code: '1030',
    block_group: '100',
    block: '1000',
    position: '1',
    option: '0',
    operate: '1',
  },
  {
    site_type: 'botvip_web',
    memo: '首页中间搜索框-搜索',
    request_type: '2',
    request_code: '1030',
    block_group: '100',
    block: '1000',
    position: '1',
    option: '0',
    operate: '2',
  },
];

export const eventMap = new Map<string, object>();

eventTypes.forEach((item) => {
  eventMap.set(item.memo, item);
});
