import './global.less';
import 'animate.css';
import type { AppProps } from 'next/app';
import { App, ConfigProvider } from 'antd';
import { configure } from 'mobx';
import CommonHead from '@/coms/head/common';
import NextProgress from 'next-progress';
import { antdTheme, setUseApp } from '@/configs/theme';
import zhCN from 'antd/locale/zh_CN';
import { useRouterChange } from '@/modules/loadedHook';
import { useRouter } from 'next/router';
import commonStore from '@/stores/common';
import { useEffect } from 'react';
import Script from "next/script";
import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';

if (typeof process !== 'undefined') {
  process.on('uncaughtException', (e) => console.error('node未处理异常', e.stack));
  process.on('unhandledRejection', (e) => console.error('node未处理Promise错误', e));
}

configure({ enforceActions: 'always' });

function SetUseApp() {
  setUseApp(App.useApp());
  return null;
}

export default function _App({ Component, pageProps }: AppProps) {
  const { isReady } = useRouter();
  const { from } = useRouter().query as { from: string };
  useEffect(() => {
    if (isReady) {
      commonStore.updateNextReady(true);
      if (!Object.hasOwn) {
        Object.hasOwn = (obj, prop) =>
          Object.prototype.hasOwnProperty.call(obj, prop);
      }
    }
  }, [isReady]);

  useRouterChange();

  return (
    <>
      <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
        <ConfigProvider theme={antdTheme} locale={zhCN}>
          <App style={{ overflow: 'hidden' }}>
            <SetUseApp />
            <CommonHead />
            <Component {...pageProps} />
            <NextProgress color="var(--base-gradient-color)" options={{ showSpinner: false }} />
          </App>
        </ConfigProvider>
      </StyleProvider>
      <Script id="clarity-script" strategy="afterInteractive">
        {` (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "llzwx4g6hl");`}
      </Script>
      <Script id="baidu-js" strategy="afterInteractive">
        {
          `var _hmt = _hmt || [];
          (function() {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?62ae539cc3e43e35bb3626c26497c44a";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
          })();`
        }
      </Script>
    </>
  );
}
