import { getDate } from '@/coms/activity/recharge';
import { message } from '@/configs/theme';
import userInfoStore from '@/stores/userInfo';

export const PICURL = 'https://pic.ichuangnao.com/'; // 阿里oss 资源域名

export async function copyString(s: string, { alert = false } = {}) {
  try {
    await navigator.clipboard.writeText(s);
  } catch (err) {
    const el = document.createElement('textarea');
    el.value = s;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      (document.getSelection()?.rangeCount || 0) > 0
        ? document.getSelection()?.getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection()?.removeAllRanges();
      document.getSelection()?.addRange(selected);
    }
  }

  if (alert) {
    message.success('复制成功');
  }
}

/**
 * 复制文本到剪切板中
 *
 * @export
 * @param {*} value 需要复制的文本
 * @param {*} cb 复制成功后的回调
 */
export function copy(value: String, cb: Function) {
  // 动态创建 textarea 标签
  const textarea: any = document.createElement('textarea');
  // 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
  textarea.readOnly = 'readonly';
  textarea.style.position = 'absolute';
  textarea.style.left = '-9999px';
  // 将要 copy 的值赋给 textarea 标签的 value 属性
  // 网上有些例子是赋值给innerText,这样也会赋值成功，但是识别不了\r\n的换行符，赋值给value属性就可以
  textarea.value = value;
  // 将 textarea 插入到 body 中
  document.body.appendChild(textarea);
  // 选中值并复制
  textarea.select();
  textarea.setSelectionRange(0, textarea.value.length);
  document.execCommand('Copy');
  document.body.removeChild(textarea);
  if (cb && Object.prototype.toString.call(cb) === '[object Function]') {
    cb(true);
  } else {
    cb(false);
  }
}
// 首字母大写
export function capitalizeFirstLetter(str = '') {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getUrlParam(name: string) {
  const urlParams = new URLSearchParams(location.search);
  return urlParams.get(name) || '';
}

export function addUrlParam(name: string, value?: string) {
  value = value || getUrlParam(name);
  return value ? '?' + name + '=' + value : '';
}

// 获取弹出窗口通用设置并居中于屏幕
export function getOpenWinCommonSetting(width = 800, height = 450) {
  const { screen } = window;
  const top = (screen.availHeight - height) / 2 + ((screen as any).availTop || 0);
  const left = (screen.availWidth - width) / 2 + ((screen as any).availLeft || 0);
  return `width=${width},height=${height},top=${top},left=${left},menubar=0,scrollbars=1,resizable=1,status=1,titlebar=0,toolbar=0,location=1`;
}

export const specialUserLog = (t = '') => {
  if (!userInfoStore.isSpecialUser) return;
  console.log('%c特殊用户log', 'color:#fff;background: #11ddcc', t, '时间:', getDate());
};
