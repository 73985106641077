import { getApiRootUrl, getMiddleApiRootUrl } from '@/configs/url';
// import { cookieGet } from './cookie';
import { getIsClient, getIsServer, isProd } from './env';
import { specialUserLog } from './common';
import Cookies from 'js-cookie';
// import { getDeviceType } from './buryPoint';

export interface FetchApiOptions extends RequestInit {
  params?: Record<string, string | number | null | undefined>;
  data?: Record<string, any>;
  isMiddle?: boolean;
  upload?: boolean;
}

export interface FetchApiExtraOptions<T> {
  notJson?: boolean;
  full?: boolean;
  errorCb?: (json: any) => void;
  onSSE?: (data: T) => void;
  successCode?: number[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function fetchApiDefaultErrorCb(json: any) {
  // 可以在这里统一处理错误
  // console.error(json)
  // ...
}

export type FetchApi<T = unknown> = Promise<T> & { abort: () => void };

export default function fetchApi<T>(
  url: string,
  options?: FetchApiOptions,
  {
    notJson = false,
    full = false,
    // 这里传入的函数的作用是错误的通用（常规）处理，特定某个api的错误应使用.catch处理。
    // 此处的参数只是为了增加灵活性
    errorCb = fetchApiDefaultErrorCb,
    onSSE,
    successCode = [1, 200, 2000],
  }: FetchApiExtraOptions<T> = {},
): FetchApi<T> {
  let _url = url;
  const _options: RequestInit = { ...options };

  if (options?.params) {
    const params = new URLSearchParams();
    Object.entries(options.params).forEach(([key, value]) => {
      if (value === '' || value == null) return;
      params.append(key, value as string);
    });
    _url = `${url}?${params.toString()}`;
  }
  _options.headers = {
    ..._options.headers,
    Accept: 'application/json, text/plain, */*',
  };
  if (options?.data && !options?.upload) {
    _options.body = JSON.stringify(options.data);
    _options.headers = {
      ..._options.headers,
      'Content-Type': 'application/json',
    };
  }
  if (options?.upload) {
    let formData = new FormData();
    for (let key in options.data) {
      if (options.data[key] instanceof Array) {
        options.data[key].forEach((item: any) => {
          formData.append(key, item);
        });
      } else {
        formData.append(key, options.data[key]);
      }
    }
    _options.body = formData;
    _options.headers = {
      ..._options.headers,
    };
  }
  let appId: any = '';
  let authorization: any = '';
  if (Cookies && Cookies.get) {
    appId = Cookies.get('app_id');
    authorization = Cookies.get('authorization');
  }
  if (appId && options?.isMiddle) {
    _options.headers = {
      ..._options.headers,
      Appid: appId,
      Authorization:
        // authorization,
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywicGhvbmUiOiIxNzg2MzA3NjMzNyIsIm5hbWUiOiJcdTRlZTNcdTc4MDFcdTdjZDVcdTYyNGIiLCJwb3J0cmFpdCI6InN0YXRpYy9pbWFnZXMvZmFjZS91MTEucG5nIiwidXVpZCI6IjZlOWY0ZDRjIiwiaXNfcmVnaXN0ZXIiOjAsImFwcF9pZCI6IjQ4MjI2NTAwMDQyOTU5NTkiLCJleHAiOjE3MTQ3MDg5MTR9.1tSxBlrC0ahRHKDgwOYsLlIO4ZAAP_sQa1bmEJGI0Gc',
      Authorization2: authorization,
    };
  }
  // _options.headers = {
  //   Authorization: cookieGet('token') || cookieGet('shutu_access_token'),
  //   'X-Device-Type': getIsServer() ? '4' : (await getDeviceType()) + '',
  //   ..._options.headers,
  // };
  // if (!(_options.headers as any).Authorization) {
  //   delete (_options.headers as any).Authorization;
  // }

  let abortController: AbortController | undefined;
  if (typeof AbortController !== 'undefined') {
    abortController = new AbortController();
    _options.signal = abortController.signal;
  }

  let finished = false;
  const promise = fetch(`${options?.isMiddle ? getMiddleApiRootUrl() : getApiRootUrl()}${_url}`, {
    ..._options,
    mode: 'cors',
    credentials: options?.isMiddle ? undefined : 'include',
  }).then(async (res) => {
    if (onSSE) {
      const reader = res.body!.getReader();
      const decoder = new TextDecoder();

      let lastMessage: T;
      return reader.read().then(function read({ done, value }): Promise<T> | T {
        if (done) {
          finished = true;
          return lastMessage;
        }

        let data = decoder.decode(value);

        const pattern = /data: (.+?)\n\n/g;
        const matches = [];

        let match;
        while ((match = pattern.exec(data)) !== null) {
          matches.push(match[1]);
        }

        if (!isProd && matches.length > 1) {
          console.warn('sse api: ', url, ' 发生多条数据: ', data);
        }

        specialUserLog(JSON.stringify(matches));

        matches.forEach((data) => {
          if (!data) {
            throw new Error(`sse api: ${url} 发生格式错误: ${data}`);
          }
          if ((window as any).__debug) {
            console.log('sse data', data);
          }

          let message: T;
          if (notJson) {
            message = data as unknown as T;
          } else if (full) {
            message = JSON.parse(data) as T;
          } else {
            const json = JSON.parse(data);
            if (!successCode.includes(json.code)) {
              errorCb(json);
              const error: Error & { code?: number; data?: any; msg?: string } = new Error(
                `sse api: ${url} 发生错误: ${(json.msg as string) || '未知错误'}`,
              );
              error.code = json.code;
              error.data = json.data;
              error.msg = json.msg;
              throw error;
            }
            message = json.data;
          }
          lastMessage = message;
          onSSE(message);
        });

        return reader.read().then(read);
      });
    } else {
      finished = true;
      let result: T;
      if (notJson) {
        result = res as unknown as T;
      } else if (full) {
        result = res.json() as T;
      } else {
        const json = await res.json();
        if (!successCode.includes(json.code)) {
          errorCb(json);
          const error: Error & { code?: number; data?: any; msg?: string } = new Error(
            `请求api: ${url} 发生错误: ${(json.msg as string) || '未知错误'}`,
          );
          error.code = json.code;
          error.data = json.data;
          error.msg = json.msg;
          throw error;
        }
        result = json.data;
      }

      return result;
    }
  });

  (promise as FetchApi<T>).abort = () => {
    try {
      if (!finished) {
        abortController!.abort();
      }
    } catch (e) {
      console.warn('abort error', e);
    }
  };
  return promise as FetchApi<T>;
}

// for debug
if (getIsClient()) {
  (window as any).__fetchApi = fetchApi;

  (window as any).__testFetchApi = () =>
    fetchApi(
      '/ai/build-chat',
      { method: 'post', data: { model_id: 2, chat_id: 1, subject: '水浒传人物' } },
      {
        onSSE: (m) => console.log('sse', Date.now(), m),
      },
    )
      .then((res) => console.log('res', res))
      .catch((e) => console.error('catch', e));
}
