import { modal } from '@/configs/theme';
import BindPhone from '../userInfo/bindPhone';
import userInfoStore from '@/stores/userInfo';

let bindPhoneModalHelper: any;

function BindPhoneModal() {
  const onClose = () => {
    bindPhoneModalHelper.destroy();
    bindPhoneModalHelper = undefined;
  };

  return (
    <BindPhone
      onClose={onClose}
      onSuccess={() => {
        userInfoStore.updateUserInfo();
        onClose();
      }}
    />
  );
}

export function commonShowBindPhoneModal() {
  bindPhoneModalHelper = modal.info({
    content: <BindPhoneModal />,
    closeIcon: false,
    footer: null,
    icon: null,
    centered: true,
    width: 411,
    className: 'clearAntdModalContentPadding hiddenAntdModalContentOverflow',
    maskClosable: true,
    maskStyle: { backgroundColor: 'rgba(0, 0, 0, 0.4)' },
  });
}
