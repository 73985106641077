import fetchApi from '@/utils/fetchApi';
import mergePromiseCall from '@/utils/mergePromiseCall';

export interface Model {
  desc_content: string;
  desc_title: string;
  description: string;
  icon: string;
  id: number;
  is_default: number;
  is_set_multi: number;
  is_vip: number;
  is_check_forbidden: number;
  model_name: string;
  sign: string;
  model_supplier: string;
  title: string;
  stats_position: number;
  use_num: number;
  sort: number;
  top_sort: number;
  is_enable_citation: number;
}

export async function getModelList({
  page = 1,
  page_size = 100,
  is_support_system = 0,
  decoratedFetchApi = fetchApi,
}: {
  page?: number;
  page_size?: number;
  is_support_system?: number;
  decoratedFetchApi?: typeof fetchApi;
} = {}) {
  return decoratedFetchApi<{ list: Model[] }>('/model/list', {
    params: { page, page_size, is_support_system },
  });
}

export async function getModelListMiddle({
  decoratedFetchApi = fetchApi,
}: {
  decoratedFetchApi?: typeof fetchApi;
} = {}) {
  return decoratedFetchApi<any>('/bot/model_list', {
    isMiddle: true,
  });
}
// export const getModelList = mergePromiseCall(() => fetchApi<{ list: Model[] }>('/model/list'), {
//   persist: true,
//   // argComparer: () => true,
//   ttl: 60 * 1000,
// });

export interface ModelDetail {
  desc_content: string;
  desc_title: string;
  description: string;
  icon: string;
  id: number;
  is_default: number;
  is_set_multi: number;
  is_vip: number;
  model_name: string;
  model_supplier: string;
  recommend_prompt: {
    prompt: string;
    title: string;
    [property: string]: any;
  }[];
  title: string;
  [property: string]: any;
  is_enable_citation: number;
}

// export async function getModelDetail({
//   decoratedFetchApi = fetchApi,
//   modelId,
// }: {
//   decoratedFetchApi?: typeof fetchApi;
//   modelId: number;
// }) {
//   return decoratedFetchApi<ModelDetail>('/model/info', { params: { model_id: modelId } });
// }
export const getModelDetail = mergePromiseCall(
  ({ id, sign }: { id?: number; sign?: string }) =>
    fetchApi<ModelDetail>('/model/info', { params: { model_id: id, sign } }),
  {
    persist: true,
    ttl: 60 * 1000 * 5,
  },
);

// 获取多模型推荐列表
export function getRecommendModelList({ decoratedFetchApi = fetchApi } = {}) {
  return decoratedFetchApi<{
    list?: {
      description: string;
      icon: string;
      model_id: number;
      name: string;
      title: string;
    }[];
  }>('/model/multi-recommend-list');
}

export interface List {
  children?: Child[];
  icon?: string;
  name?: string;
}

export interface Child {
  id?: number;
  prompt?: string;
  title?: string;
}

export function getRecommendPromptList({ decoratedFetchApi = fetchApi } = {}) {
  return decoratedFetchApi<{
    list?: List;
  }>('/model/recommend-prompt-list');
}
