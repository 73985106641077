import fetchApi from '@/utils/fetchApi';

export type ChatType = 'single' | 'multi' | 'aibot' | 'aibotHelper' | 'userBot';

export const chatTypeMap: Record<ChatType, number> = {
  single: 1,
  multi: 2,
  aibot: 3,
  aibotHelper: 4,
  userBot: 5,
};

export async function createChatGroup({
  decoratedFetchApi = fetchApi,
  chatType,
  modelIds,
  botId,
  title,
  docName,
  docId,
  userBotId,
  chatStatus,
  currentRoute,
}: {
  decoratedFetchApi?: typeof fetchApi;
  chatType: ChatType;
  modelIds?: number[];
  botId?: number;
  title?: string;
  docId?: number;
  docName?: string;
  userBotId?: number;
  chatStatus?: number;
  currentRoute?: boolean;
}) {
  const apiPath = currentRoute ? '/ai-chat/chat-group-create-share' : '/ai-chat/chat-group-create';
  return decoratedFetchApi<{
    chat_ids: number[];
    group_hex: string;
    group_id: number;
  }>(apiPath, {
    method: 'post',
    data: {
      chat_type: chatTypeMap[chatType],
      model_ids: modelIds,
      bot_id: botId,
      title: title || docName,
      document_id: docId,
      user_bot_id: userBotId,
      chat_status: chatStatus,
    },
  });
}

export interface ChatGroup {
  chat_type: number;
  create_time: string;
  hex: string;
  id: number;
  model_id: number;
  bot_id: number;
  status: number;
  title: string;
  update_time: string;
  head_portrait: string;
  name: string;
  last_reply_content: string;
  vip_type: number;
  user_bot_id: number;
}

export async function getChatGroupList({
  decoratedFetchApi = fetchApi,
  chatType,
  modelId,
  botId,
  userBotId,
  chatStatus,
  page,
  pageSize,
  isHistory,
  currentRoute = false,
}: {
  decoratedFetchApi?: typeof fetchApi;
  chatType?: ChatType;
  modelId?: number;
  botId?: number;
  userBotId?: number;
  chatStatus?: number;
  page: number;
  pageSize: number;
  isHistory?: boolean;
  currentRoute?: boolean;
}) {
  const apiPath = currentRoute ? '/ai-chat/chat-group-list-share' : '/ai-chat/chat-group-list';
  return decoratedFetchApi<{
    count: number;
    list: ChatGroup[];
  }>(apiPath, {
    params: {
      chat_type: chatType ? chatTypeMap[chatType] : undefined,
      model_id: modelId,
      bot_id: botId,
      user_bot_id: userBotId,
      chat_status: chatStatus,
      page,
      page_size: pageSize,
      is_history: isHistory ? 1 : 0,
    },
  });
}

export async function getChatGroupDetail({
  decoratedFetchApi = fetchApi,
  groupId,
  groupHex,
}: {
  decoratedFetchApi?: typeof fetchApi;
  groupId?: number;
  groupHex?: string;
}) {
  return decoratedFetchApi<{
    list: {
      bot_id: number;
      chat_id: number;
      chat_title: string;
      count: number;
      model_id: number;
      document_id: number;
      sort: number;
    }[];
  }>('/ai-chat/group-model-list-share', { params: { group_id: groupId, group_hex: groupHex } });
}

export async function updateChatGroup({
  decoratedFetchApi = fetchApi,
  groupId,
  groupHex,
  title,
  toDel,
}: {
  decoratedFetchApi?: typeof fetchApi;
  groupId?: number;
  groupHex?: string;
  title?: string;
  toDel?: boolean;
}) {
  return decoratedFetchApi<void>('/ai-chat/chat-group-update', {
    method: 'post',
    data: {
      group_id: groupId,
      group_hex: groupHex,
      title,
      status: toDel ? -1 : 1,
    },
  });
}

export async function createChat({
  decoratedFetchApi = fetchApi,
  modelId,
  groupId,
  groupHex,
}: {
  decoratedFetchApi?: typeof fetchApi;
  modelId: number;
  groupId?: number;
  groupHex?: string;
}) {
  return decoratedFetchApi<{ chat_id: number }>('/ai-chat/group-model-create', {
    method: 'post',
    data: {
      group_id: groupId,
      group_hex: groupHex,
      model_id: modelId,
    },
  });
}

export async function delChat({
  decoratedFetchApi = fetchApi,
  chatId,
}: {
  decoratedFetchApi?: typeof fetchApi;
  chatId: number;
}) {
  return decoratedFetchApi<void>('/ai-chat/group-model-delete', {
    method: 'post',
    data: { chat_id: chatId },
  });
}

export interface ChatItem {
  build_status?: number;
  content?: string;
  create_time?: string;
  id?: number;
  prompt?: string;
  origin_prompt?: string;
  search_results?: any[];
  status?: number;
}

export async function getChatList({
  decoratedFetchApi = fetchApi,
  chatId,
  offset,
  pageSize,
  currentRoute,
}: {
  decoratedFetchApi?: typeof fetchApi;
  chatId: number;
  offset: number;
  pageSize: number;
  currentRoute?: boolean;
}) {
  const apiPath = currentRoute ? '/ai-chat/chat-list-share' : '/ai-chat/chat-list';
  return decoratedFetchApi<{
    count: number;
    list: ChatItem[];
  }>('/ai-chat/chat-list-share', { params: { chat_id: chatId, offset, page_size: pageSize } });
}

export async function createChatItem({
  decoratedFetchApi = fetchApi,
  chatIds,
  prompt,
  chatType,
  modelId,
  botId,
  docId,
  userBotId,
  currentRoute,
}: {
  decoratedFetchApi?: typeof fetchApi;
  chatIds: number[];
  prompt: string;
  chatType: ChatType;
  modelId?: number;
  botId?: number;
  docId?: number;
  userBotId?: number;
  currentRoute?: boolean;
}) {
  const apiPath = currentRoute ? '/ai-chat/chat-create-share' : '/ai-chat/chat-create';
  return decoratedFetchApi<{ chat_record_ids: number[]; code: number }>(apiPath, {
    method: 'post',
    data: {
      chat_ids: chatIds,
      prompt,
      chat_type: chatTypeMap[chatType],
      model_id: modelId,
      bot_id: botId,
      document_id: docId,
      user_bot_id: userBotId,
    },
  });
}

export interface ChatData {
  content: string;
  is_success: number;
  deduct_ai_word_num: number; // 消耗的ai字数
  remaining_ai_word_num: number; // 剩余的ai字数
  deduct_ai_num: number; // 消耗的ai次数
  remaining_ai_num: number; // 剩余的ai次数
  search_results?: any[];
}

export function chat({
  decoratedFetchApi = fetchApi,
  botId,
  modelId,
  docId,
  userBotId,
  chatRecordId,
  subject,
  onSSE,
  previousSubject,
  previousContent,
  onLine,
  currentRoute,
}: {
  decoratedFetchApi?: typeof fetchApi;
  botId?: number;
  modelId?: number;
  docId?: number;
  userBotId?: number;
  chatRecordId: number;
  subject: string;
  onSSE: (d: ChatData) => void;
  previousSubject?: string;
  previousContent?: string;
  onLine?: boolean;
  currentRoute?: boolean;
}) {
  const apiPath = currentRoute ? '/ai/build-chat-share' : '/ai/build-chat';
  return decoratedFetchApi<ChatData>(
    apiPath,
    {
      method: 'post',
      data: {
        model_id: modelId,
        bot_id: botId,
        chat_record_id: chatRecordId,
        document_id: docId,
        user_bot_id: userBotId,
        subject,
        previous_subject: previousSubject,
        previous_content: previousContent,
        enable_citation: onLine,
      },
    },
    { onSSE, successCode: [1, 100] },
  );
}

export function getChatRecommendPromptList({
  question,
  recordId,
  decoratedFetchApi = fetchApi,
}: {
  question?: string;
  recordId?: number;
  decoratedFetchApi?: typeof fetchApi;
}) {
  return decoratedFetchApi<{ list: [] }>('/ai-chat/chat-recommend-prompt-list', {
    method: 'post',
    data: { question, chat_record_id: recordId },
  });
}

export function getChatShareCreate({
  chatId,
  chatIds,
  chatRecordIds,
  groupHex,
  modelId,
  modelIds,
  aibotId,
  shareType,
  decoratedFetchApi = fetchApi,
}: {
  chatId?: number;
  chatIds?: number[];
  chatRecordIds?: number[];
  groupHex?: string;
  modelId?: number;
  modelIds?: number[];
  aibotId?: number;
  shareType: 1 | 2 | 3; // 1: 单模型 2: 多模型 3: 机器人
  decoratedFetchApi?: typeof fetchApi;
}) {
  return decoratedFetchApi<{
    share_hex: string;
    share_id: number;
  }>('/ai-chat/chat-share-create', {
    method: 'post',
    data: {
      chat_id: chatId,
      chat_ids: chatIds,
      chat_record_ids: chatRecordIds,
      group_hex: groupHex,
      model_id: modelId,
      model_ids: modelIds,
      bot_id: aibotId,
      share_type: shareType,
    },
  });
}

interface ChatHistory {
  id: number;
  model_detail: {
    id: number;
    title: string;
    desc_title: string;
    desc_content: string;
    icon: string;
  };
  model_id: number;
}

export function getChatShareDetail({
  id,
  hex,
  decoratedFetchApi = fetchApi,
}: {
  id?: number;
  hex?: string;
  decoratedFetchApi?: typeof fetchApi;
}) {
  return decoratedFetchApi<{
    chat_list: {
      chat_history_list?: ChatHistory[];
      id: number;
      model_id: number;
      model_detail: {
        id: number;
        title: string;
        desc_title: string;
        desc_content: string;
        icon: string;
      };
    }[];
    chat_history_list: ChatHistory[];
    model_icon: string;
    model_id: number;
    model_name: string;
    model_title: string;
    bot_id: number;
    bot_name: string;
    bot_icon: string;
    share_head_portrait: string;
    share_show_uid: string;
    share_nickname: string;
  }>('/ai-chat/chat-share-detail', {
    params: {
      share_id: id,
      share_hex: hex,
    },
  });
}

export interface ChatData {
  content: string;
  is_success: number;
  deduct_ai_word_num: number; // 消耗的ai字数
  remaining_ai_word_num: number; // 剩余的ai字数
  deduct_ai_num: number; // 消耗的ai次数
  remaining_ai_num: number; // 剩余的ai次数
}
