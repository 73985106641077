// 修改变量之后需要重新启动开发服务器
const cssVars = {
  pColor: 'var(--cy-color-6)', // 主色
  pColorGrad: 'linear-gradient(88deg, #af61f0 1.44%, var(--cy-color-6) 100%)', // 主色渐变

  // 文字颜色
  pTextColor: 'var(--qtd-fun-color-12)', // 主文字颜色
  desTextColor: 'var(--qtd-fun-color-8)', // 说明文字颜色
  placeholderTextColor: 'var(--qtd-fun-color-7)', // 输入框提示文字颜色

  // 其他颜色
  lineColor: 'var(--qtd-fun-color-4)', // 线条颜色
  hoverColor: 'var(--qtd-fun-color-3)', // 菜单hover项背景颜色
  focusColor: 'var(--cy-color-1)', // 菜单选中项背景颜色
  // bgColor: 'var(--qtd-fun-color-2)', // 背景颜色
  bgColor: '#fff', // 背景颜色

  // 字重
  fontWeightB: 600,
  fontWeightN: 500,
  fontWeightL: 400,

  font: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  transition: 'all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)',
};

/** @type {import('antd').ThemeConfig} */
const antdTheme = {
  token: {
    "colorPrimary": "#00ccb8",
    "colorInfo": "#00ccb8"
  },
  components: {
    Progress: {
      defaultColor: cssVars.pColor,
    },
    Button: {
      defaultColor: 'var(--qtd-fun-color-8)',
    },
  },
};

// 存放 message, notification, modal 等实例
// 详细信息见：https://ant-design.antgroup.com/components/app-cn

/** @type {import('antd/es/message/interface').MessageInstance} */
let message;
/** @type {import('antd/es/notification/interface').NotificationInstance} */
let notification;
/** @type {Omit<import('antd/es/modal/confirm').ModalStaticFunctions, 'warn'>} */
let modal;

function setUseApp(antd) {
  message = antd.message;
  notification = antd.notification;
  modal = antd.modal;
}

module.exports = {
  get antdTheme() {
    return antdTheme;
  },
  get cssVars() {
    return cssVars;
  },
  get message() {
    return message;
  },
  get notification() {
    return notification;
  },
  get modal() {
    return modal;
  },
  setUseApp,
};
