import { getIsClient } from '@/utils/env';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { getModelList } from '@/services/model';
import commonStore from './common';
import ChatMapStore from './chat';
import hubStore from './hub';
import collectStore from './collect';

class ModelStore extends ChatMapStore {
  list: AwaitedReturnType<typeof getModelList>['list'] | null = null;
  modelOnLine: { [key: number]: boolean } = {};

  get defaultModel() {
    return this.list?.find((item) => item.is_default === 1);
  }

  constructor() {
    super();
    makeObservable(this, {
      list: observable,
      defaultModel: computed,
      init: flow,
      getModelList: flow,
      modelOnLine: observable,
      updateModelOnLine: action,
    });
    hubStore.set({ modelStore: this });
    this.init();
  }

  *init() {
    if (getIsClient()) {
      // 解决next水合报错问题
      yield commonStore.whenNextReady();
      yield this.getModelList();
    } else {
      yield this.getModelList();
      setInterval(() => this.getModelList(), 60 * 1000);
    }
  }

  *getModelList() {
    const { list }: AwaitedReturnType<typeof getModelList> = yield getModelList();
    this.list = [...list].sort((a, b) => {
      if (a.sort !== b.sort) {
        return b.sort - a.sort;
      } else {
        return b.use_num - a.use_num;
      }
    });

    this.modelOnLine = this.list.reduce((acc: any, cur) => {
      acc[cur.id] = cur.is_enable_citation === 1;
      return acc;
    }, {});

    collectStore.checkModelCollect(this.list.map((item) => item.id));
  }

  updateModelOnLine(modelId: number, status: boolean) {
    this.modelOnLine = { ...this.modelOnLine, [modelId]: status };
  }
}

const modelStore = new ModelStore();
export default modelStore;

// for debug
if (getIsClient()) {
  (window as any).__modelStore = modelStore;
}
