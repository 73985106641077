import { observer } from 'mobx-react';
import STYLE from './index.module.less';
import { useScroll } from 'ahooks';
import { ChatGroup, getChatGroupList } from '@/services/chat';
import { useEffect, useRef } from 'react';
import GroupItem from './groupItem';
import { makeAutoObservable, when } from 'mobx';
import { getIsClient } from '@/utils/env';
import commonStore from '@/stores/common';
import userInfoStore from '@/stores/userInfo';

class GroupListStore {
  list: ChatGroup[] | null = null;
  listForLeftBar: ChatGroup[] | null = null;
  listFetching: boolean = false;
  listCount: number = 0;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.init();
  }

  updateList = (v: ChatGroup[] | null) => {
    this.list = v;
  };

  updateListForLeftBar = (v: ChatGroup[] | null) => {
    this.listForLeftBar = v;
  };

  *init() {
    if (getIsClient()) {
      // 解决next水合报错问题
      yield commonStore.whenNextReady();
      yield this.getListForLeftBar();
    }
  }

  *getList({ pageSize = 10, forNext = true }: { pageSize?: number; forNext?: boolean } = {}) {
    yield when(() => userInfoStore.isLogged === 'logged');
    // forNext 表示是否是在请求下一页
    if (this.listFetching) return;
    this.listFetching = true;

    if (!this.list || !forNext) {
      // 第一次请求或者刷新数据
      const { list, count }: AwaitedReturnType<typeof getChatGroupList> = yield getChatGroupList({
        page: 1,
        pageSize,
        isHistory: true,
      });
      this.list = list;
      this.listCount = count;
    } else if (this.list.length < this.listCount) {
      // 请求下一页
      const { list, count }: AwaitedReturnType<typeof getChatGroupList> = yield getChatGroupList({
        page: this.list?.length / pageSize + 1,
        pageSize,
        isHistory: true,
      });
      this.list.push(...list);
      this.listCount = count;
    }

    this.listFetching = false;
  }

  *getListForLeftBar({ pageSize = 5, userBotId }: { pageSize?: number; userBotId?: number; } = {}) {
    try {
      const currentRoute = typeof window !== 'undefined' && window.location.pathname.includes('/aibot/uNew');
      const { list }: AwaitedReturnType<typeof getChatGroupList> = yield getChatGroupList({
        page: 1,
        pageSize,
        isHistory: true,
        userBotId: userBotId,
        currentRoute: currentRoute ? true : false
      });
      this.listForLeftBar = list;
    } catch (e) {
      this.listForLeftBar = null;
    }
  }
}

const groupListStore = new GroupListStore();
export { groupListStore };

function GroupList({
  isLeftBar = false,
  itemContentMaxWidth = 759,
  style,
}: {
  isLeftBar?: boolean;
  itemContentMaxWidth?: number;
  style?: {};
}) {
  const listRef = useRef<HTMLDivElement>(null);

  const { list, listForLeftBar } = groupListStore;

  useEffect(() => {
    if (!isLeftBar) {
      if (userInfoStore.isLogged === 'logged') {
        groupListStore.getList();
      } else {
        groupListStore.updateList(null);
      }
    } else {
      if (userInfoStore.isLogged === 'logged') {
        groupListStore.getListForLeftBar();
      } else {
        groupListStore.updateListForLeftBar(null);
      }
    }
  }, [userInfoStore.isLogged, isLeftBar]);

  useScroll(listRef.current, ({ top }) => {
    const target = listRef.current!;
    if (target.scrollHeight === target.clientHeight) return false;
    if (target.scrollHeight - top < target.clientHeight + 200) {
      groupListStore.getList();
    }
    return false;
  });

  const reload = () => {
    groupListStore.getList({ forNext: false });
    groupListStore.getListForLeftBar();
  };

  const l = isLeftBar ? listForLeftBar : list;

  return (
    <div ref={listRef} className={STYLE.list} style={{ ...style }}>
      {l?.map((item) => (
        <GroupItem
          key={item.id}
          data={item}
          reload={reload}
          itemContentMaxWidth={itemContentMaxWidth}
        />
      ))}
    </div>
  );
}

export default observer(GroupList);
