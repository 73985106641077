import { defaultTKD } from '@/configs/common';
import { getApiRootUrl, iconparkUrl } from '@/configs/url';
import Head from 'next/head';
import Script from 'next/script';

export default function CommonHead() {
  return (
    <>
      <Head>
        <link rel="icon" href={require('@/../public/favicon.ico').default.src} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"
        />
        <meta name="renderer" content="webkit" />
        <link ref="preconnect" href={getApiRootUrl()} />
        {/* <link ref="preconnect" href={getWsRootUrl()} /> */}

        <title>{defaultTKD.t}</title>
        <meta name="keywords" content={defaultTKD.k} />
        <meta name="description" content={defaultTKD.d} />
      </Head>
      <Script src={iconparkUrl} defer />
    </>
  );
}
