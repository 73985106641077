import { AIBotInfo } from '@/services/aibot';
import { ModelDetail } from '@/services/model';
import modelStore from '@/stores/model';

let extraMap: Record<string, any> = {
  stats_position: 0,
  model_id: 0,
  bot_id: 0,
};

const map: Record<string, Map<string, number>> = {
  stats_position: new Map([
    ['主站-左下角-充值', 3],
    ['主站头像下拉-充值', 4],
    ['主站顶部-成为会员', 5],
    ['多模型使用', 7],
    // ['单模型使用', 8],
    ['布局切换', 9],
    ['主站顶部-终身活动', 26],
    ['头像下拉-终身活动', 27],
  ]),
};

export function setExtraMap(obj: Record<string, any>) {
  for (let key in obj) {
    extraMap[key] = map[key]?.get(obj[key]) || obj[key];
  }
}

export function setExtraMapForAIWordNum({
  modelDetail,
  aibotInfo,
}: {
  modelDetail: ModelDetail | undefined;
  aibotInfo: AIBotInfo | undefined;
}) {
  if (modelDetail) {
    const chatList = Object.values(modelStore.chatStoreMap);
    const isMulti = chatList.length > 1;
    setExtraMap({
      model_id: !isMulti ? modelDetail.id : undefined,
      stats_position: isMulti ? '多模型使用' : modelDetail.stats_position,
    });
  } else if (aibotInfo) {
    setExtraMap({ bot_id: aibotInfo.id, stats_position: aibotInfo.stats_position });
  }
}

export function getExtraMap() {
  return extraMap;
}
