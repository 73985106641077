export default async function getOs() {
  try {
    if (typeof window === 'undefined') return 'sever';
    var sUserAgent = navigator.userAgent;
    var isWin = navigator.platform === 'Win32' || navigator.platform === 'Windows';
    var isMac =
      navigator.platform === 'Mac68K' ||
      navigator.platform === 'MacPPC' ||
      navigator.platform === 'Macintosh' ||
      navigator.platform === 'MacIntel';
    if (isMac) return 'mac';
    var isUnix = navigator.platform === 'X11' && !isWin && !isMac;
    if (isUnix) return 'unix';
    var isLinux = String(navigator.platform).indexOf('Linux') > -1;
    if (isLinux) return 'linux';
    if (isWin) {
      // const isWin11 = await isWin11Os()
      // if (isWin11) return 'Windows:11'
      var isWin2K =
        sUserAgent.indexOf('Windows NT 5.0') > -1 || sUserAgent.indexOf('Windows 2000') > -1;
      if (isWin2K) return 'Windows:2000';
      var isWinXP =
        sUserAgent.indexOf('Windows NT 5.1') > -1 || sUserAgent.indexOf('Windows XP') > -1;
      if (isWinXP) return 'Windows:xp';
      var isWin2003 =
        sUserAgent.indexOf('Windows NT 5.2') > -1 || sUserAgent.indexOf('Windows 2003') > -1;
      if (isWin2003) return 'Windows:2003';
      var isWinVista =
        sUserAgent.indexOf('Windows NT 6.0') > -1 || sUserAgent.indexOf('Windows Vista') > -1;
      if (isWinVista) return 'Windows:vista';
      var isWin7 =
        sUserAgent.indexOf('Windows NT 6.1') > -1 || sUserAgent.indexOf('Windows 7') > -1;
      if (isWin7) return 'Windows:7';
      var isWin8 =
        sUserAgent.indexOf('Windows NT 6.1') > -1 || sUserAgent.indexOf('Windows 8') > -1;
      if (isWin8) return 'Windows:8';
      var isWin10 =
        sUserAgent.indexOf('Windows NT 10') > -1 || sUserAgent.indexOf('Windows 10') > -1;
      if (isWin10) return 'Windows:10';
      var isAndroid = sUserAgent.indexOf('Android') > -1 || sUserAgent.indexOf('Adr') > -1;
      if (isAndroid) return 'Android';
      var isiOS =
        !!sUserAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ||
        sUserAgent.indexOf('iPhone') > -1 ||
        sUserAgent.indexOf('iPad') > -1;
      if (isiOS) return 'IOS';
      return 'Windows:other';
    }
  } catch (error) {
    return 'Unknown';
  }
  return 'other';
}

// async function isWin11Os() {
//   try {
//     const { platformVersion } = await navigator.userAgentData.getHighEntropyValues(['platformVersion'])
//     return platformVersion.split('.')[0] >= 13
//   } catch (e) {
//     return false
//   }
// }
