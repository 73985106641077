import STYLE from './index.module.less';
import Icon from '@/coms/icon';
import Image from 'next/image';
import clx from 'classnames';

function AIHeadPortrait({
  className,
  src,
  size,
  style,
  isVip,
}: {
  className?: string;
  src: string;
  size: number;
  style?: React.CSSProperties;
  isVip: boolean;
}) {
  return (
    <div
      className={clx(STYLE.root, className)}
      style={{ ...style, minWidth: size, width: size, height: size }}
    >
      <Image
        src={src || require('@/assets/image/multiChat_2.png')}
        alt="aiHeadPortrait"
        width={size}
        height={size}
      />
      {isVip ? <Icon className={STYLE.vip_icon} name={'huiyuan'} size={(14 / 34) * size} /> : null}
    </div>
  );
}

export default AIHeadPortrait;
