/**
 * 埋点
 * 发送请求地址
 * https://analytics.shutu.cn/sa.gif?d=
 */

import Cookies from 'js-cookie';
import Router from 'next/router';
import { Base64 } from 'js-base64';
import {
  isDev,
  isTest,
  isProd,
  getIsApp,
  getWXMiNiProgram,
  getAppChannelSign,
  getIsClient,
} from '../env';
import getOs from './os';
import { v4 as uuidv4, version as uuidVersion, validate as uuidValidate } from 'uuid';
import { eventMap } from './memo';
import { debounce } from 'lodash-es';
import userInfoStore from '@/stores/userInfo';
import fetchApi from '../fetchApi';
import { getExtraMap } from './extra';
import commonStore from '@/stores/common';
import { getUrlParam } from '../common';
// import Query from '../queryParams';
// import { getDocumentReferrer, getLocationPathname } from '../window';

// import moment from 'moment';
/**
 * @param {Number} request_type  请求类型:1-访问,2-点击,3-操作回调,4-弹出展现,5-数据请求;默认值:1
 * @param {Number} recharge_type  充值路径统计类型:1-展现,2-点击,3-访问
 *
 */
const getExpire = () => {
  return new Date(new Date().setHours(23, 59, 59, 999));
};

let visible = false;

if (getIsClient()) {
  const resizeObserver = new ResizeObserver(() => {
    visible = true;
    console.log('buryPoint: visible', visible);
  });
  resizeObserver.observe(document.body);
}

export async function getUUid() {
  const key = 'suuid';
  const uvtime = 'uv_time';
  let s = localStorage.getItem(key) || Cookies.get(key);
  let uv_time: any = localStorage.getItem(uvtime) || Cookies.get(uvtime);
  if (!s || !uuidValidate(s) || uuidVersion(s) !== 4) {
    s = uuidv4();
    uv_time = Math.floor(new Date().getTime() / 1000);
  }
  Cookies.set(key, s);
  Cookies.set(uvtime, uv_time);
  localStorage.setItem(key, s);
  localStorage.setItem(uvtime, uv_time);
  return {
    guid: s,
    uv_time,
  };
}

function getSourceInfo({
  decoratedFetchApi = fetchApi,
  fk,
  from,
  group,
  http_referer_host,
  invite_code,
  referrer_url,
  screen_resolution,
}: {
  decoratedFetchApi?: typeof fetchApi;
  fk: number;
  from: string | string[];
  group: string | string[];
  http_referer_host: string;
  invite_code: string | null;
  referrer_url: string;
  screen_resolution: string;
}) {
  return decoratedFetchApi('/visit-from/visit-from-info', {
    method: 'post',
    data: {
      fk,
      from,
      group,
      http_referer_host,
      invite_code,
      referrer_url,
      screen_resolution,
    },
  });
}

export const getTodayReferrer = async () => {
  if (Cookies.get('today_referrer_sign')) {
    return Cookies.get();
  }

  await commonStore.whenNextReady();

  return getSourceInfo({
    http_referer_host: window.document.referrer || '',
    from: Router.query.from || Cookies.get('url_from') || getAppChannelSign() || '',
    fk: !!Router.query.fk ? +Router.query.fk : 0,
    referrer_url: Router.pathname || '',
    screen_resolution: '',
    group: Router.query.group || '',
    invite_code: localStorage.getItem('invite_code'),
  }).then((res: any) => {
    if (!res.info) {
      res.info = res.data.info;
    }
    const expire = getExpire();
    Object.keys(res.info).forEach((v) => {
      Cookies.set(v, res.info[v], {
        path: '/',
        domain: isDev ? 'localhost' : '.changwenai.com',
        expires: expire,
      });
    });
    return Cookies.get();
  });
};

export const getDeviceType = async () => {
  const isApplet = await getWXMiNiProgram();
  if (isApplet) return 7;
  return getIsApp() ? 5 : 4;
};

function getUserInfo() {
  if (userInfoStore.isLogged === 'logged') {
    return userInfoStore.userInfo;
  }

  const local = window.localStorage.getItem('userInfo');
  if (local) {
    return JSON.parse(local) as typeof userInfoStore.userInfo;
  }

  return {};
}

interface EventData {
  guid: string;
  request_type: number; // 请求类型: 1-访问, 2-点击, 3-操作回调
  request_code: number; // 请求页面code
  block: number; // 页面区域(点击埋点)
  position: number; // 页面区域(点击埋点)
  uid: number; // 用户id
  is_filter: number; // 是否在统计的时候进行过滤
  user_type: number; // 用户类型: 0-普通, 1-内部, 4-兼职
  block_group: number; // 模块分组
  option: number; // 选项
  operate: number; // 操作
  site_type: string; // 业务线类型
  user_create_time: number; // 用户注册时间
  request_url: string; // 请求链接
  uv_time: number; // 第一次访问的时间
  is_vip: number; // 是否vip
  is_spider: number; // 是否是搜索引擎蜘蛛
  today_referrer_id: number; // 当日访问来源ID
  today_referrer_time: number; // 当日访问时间
  record_time: number; // 发送埋点记录时的时间
  env: string; // 请求环境
  http_referer: string; // 上一个页面地址
  today_referrer_sign: string; // 当日访问来源标记
  first_referrer_sign: string; // 首次访问来源标记
  today_referrer_fk: number; // 当日SEM关键词ID
  first_referrer_fk: number; // 首次SEM关键词ID
  first_referrer_id: number; // 首次访问来源ID
  referer_from: string; // 来源标记
  stats_position: number; // 充值路径统计码
  model_id: number; // 模型id
  bot_id: number; // 机器人id
  device_type: number; // 设备类型
  operating_system: string; // 操作系统
  screen_resolution: string; // 屏幕分辨率
  ai_build_word_num: number; // ai生成字数
  query: string; // query
  keyword: string; // 搜索关键词
}

const buryPoint = async (
  g: {
    site_type?: string;
    request_type?: number;
    request_code?: number;
    block_group?: number;
    block?: number;
    position?: number;
    option?: number;
    [key: string]: any;
  } = {},
) => {
  if (!visible) return;
  const o: EventData = {} as EventData;
  // o.device_type = await getDeviceType();
  o.device_type = 1;
  o.operate = +(g.operate || 0);
  o.block_group = +(g.block_group || 100);
  o.option = +(g.option || 0);
  await getTodayReferrer();
  const cookies = Cookies.get();
  o.referer_from = cookies?.referer_from || '';
  o.is_spider = +(cookies?.is_spider || 0);
  o.today_referrer_id = +(cookies?.today_referrer_id || 0);
  o.today_referrer_time = +(cookies?.today_referrer_time || Math.floor(Date.now() / 1000));
  o.today_referrer_fk = +(cookies?.today_referrer_fk || 0);
  o.today_referrer_sign = cookies?.today_referrer_sign || '';
  o.is_filter = +(cookies?.is_filter || 0);
  const userInfo = getUserInfo();
  o.user_type = +(userInfo.user_type || 0);
  o.first_referrer_id = +(userInfo.first_referrer_id || 0);
  o.first_referrer_fk = +(userInfo.first_referrer_fk || 0);
  o.first_referrer_sign = userInfo.first_referrer_sign || '';
  o.user_create_time = +(userInfo.user_create_time || 0);
  o.uid = +(g.uid || userInfo.uid || 0);
  o.guid = (await getUUid()).guid || '';
  o.uv_time = +((await getUUid()).uv_time || 0);
  o.operating_system = await getOs();

  o.site_type = g.site_type || 'botvip_web';
  o.http_referer = g.http_referer || document.referrer;

  o.position = +(g.position || 0);
  o.block = +(g.block || 1000);
  // 记录时间
  let timestamp = new Date().getTime().toString();
  timestamp = timestamp.substring(0, 10);
  o.record_time = parseInt(timestamp);
  // 项目配置

  o.request_url = g.request_url || Router?.router?.asPath;
  o.request_code = +(g.request_code || 2000);
  // 请求类型:1-访问,2-操作,3-操作回调,4-点击
  o.request_type = +(g.request_type || 0);
  o.is_vip = +(g.is_vip || userInfo?.is_vip || 0);
  o.env = g.env || (isDev ? 'dev' : isTest ? 'test' : 'prod');

  o.ai_build_word_num = +(g.ai_build_word_num || 0);

  if (o.is_filter === 0) {
    if (o.is_spider !== 0) {
      o.is_filter = 1;
    } else if (o.user_type !== 0) {
      o.is_filter = 1;
    } else if (o.env !== 'prod') {
      o.is_filter = 1;
    }
  }

  o.stats_position = +g.stats_position || getExtraMap()['stats_position'] || 0;
  o.model_id = +g.model_id || getExtraMap()['model_id'] || 0;
  o.bot_id = +g.bot_id || getExtraMap()['bot_id'] || 0;
  o.query = g.query || '';
  o.keyword = g.keyword || getUrlParam('q') || '';

  if (!isProd) {
    console.log('%cbase_point', 'color:#fff;background: #0065f0', g.memo, o);
  }

  // 主字段进行编码
  const jsonstr = JSON.stringify(o);
  const encodeData = Base64.encodeURL(jsonstr);
  const d = encodeURIComponent(encodeData);
  const t = 'botvip_stats';
  fetch('https://analytics.ichuangnao.com/sa.gif?d=' + d + '&time=' + timestamp + '&t=' + t, {
    keepalive: true,
    // @ts-ignore
    priority: 'low',
  }).catch((e) => {
    console.log(e);
  });
};

export default buryPoint;

export const buryPointMemo = (memo: string, options = {}) => {
  const args = eventMap.get(memo);
  return buryPoint({ ...args, ...options, memo });
};

export const buryPointMemoDebounce = debounce(
  (memo: string, options = {}) => buryPointMemo(memo, options),
  1000,
);
