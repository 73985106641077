// for loadScript
const __loadingScriptsHandleMaps: Record<
  string,
  {
    resolves: ((v?: any) => void)[];
    rejects: ((e: Event | string) => void)[];
  }
> = {};

export default function loadScript<T>(key: string, url: string): Promise<T> {
  return new Promise((resolve, reject) => {
    if ((window as any)[key]) {
      resolve((window as any)[key]);
      return;
    }

    if (__loadingScriptsHandleMaps[key]) {
      __loadingScriptsHandleMaps[key].resolves.push(resolve);
      __loadingScriptsHandleMaps[key].rejects.push(reject);
      return;
    }

    __loadingScriptsHandleMaps[key] = {
      resolves: [resolve],
      rejects: [reject],
    };

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    script.onload = () => {
      const { resolves } = __loadingScriptsHandleMaps[key];
      delete __loadingScriptsHandleMaps[key];
      resolves.forEach((resolve) => resolve((window as any)[key]));
    };
    script.onerror = (e) => {
      const { rejects } = __loadingScriptsHandleMaps[key];
      delete __loadingScriptsHandleMaps[key];
      rejects.forEach((reject) => reject(e));
    };

    document.head.appendChild(script);
  });
}
