import STYLE from './index.module.less';
import clx from 'classnames';
import Icon from '@/coms/icon';

function Option({
  name,
  price,
  annotation,
  selected,
  recommend,
  sign = '¥',
  onClick,
}: {
  name?: string;
  price?: number;
  annotation?: string;
  selected?: boolean;
  recommend?: boolean;
  sign?: string;
  onClick?: () => void;
}) {
  return (
    <div className={clx(STYLE.root, { [STYLE.root_selected]: selected })} onClick={onClick}>
      <div className={STYLE.name}>{name}</div>
      <div className={STYLE.price}>
        <div className={STYLE.sign}>{sign}</div>
        {price}
      </div>
      <div className={STYLE.divider}></div>
      <div className={STYLE.annotation}>{annotation}</div>
      {recommend ? <div className={STYLE.recommend}>{'限时特惠'}</div> : null}
      {selected ? (
        <div className={STYLE.selected}>
          <Icon name="wancheng" size={20} fill={'#fff'} />
        </div>
      ) : null}
    </div>
  );
}

export default Option;
