var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"yCbcFEC8dz4mpgk2Bm8At"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/build";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import sentryCommonConfig from './sentry.common.config';
import { autorun } from 'mobx';
import userInfoStore from '@/stores/userInfo';
import { ReportingObserver } from '@sentry/integrations';

Sentry.init({
  ...sentryCommonConfig,

  // Adjust this value in production, or use tracesSampler for greater control
  sampleRate: 0.3,
  tracesSampleRate: 0.1,

  replaysOnErrorSampleRate: 0.1,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    ...(sentryCommonConfig.integrations as any),
    new Sentry.Replay(),
    new ReportingObserver(),
  ],
});

autorun(() => {
  if (userInfoStore.isLogged === 'logged') {
    const { userInfo } = userInfoStore;
    Sentry.setUser({
      id: userInfo.uid,
      username: userInfo.show_uid,
    });
  }
});
