import { message } from '@/configs/theme';
import {
  UserInfo,
  VipList,
  getUserInfo,
  getUserVipInfo,
  logout,
  logoff,
  getUserSetting,
  saveUserSetting,
} from '@/services/user';
import { buryPointMemo } from '@/utils/buryPoint';
import { getIsClient } from '@/utils/env';
// import dayjs from 'dayjs';
import { autorun, makeAutoObservable } from 'mobx';
import Router from 'next/router';
import commonStore from './common';
import { commonShowBindPhoneModal } from '@/coms/bindPhone';
import hubStore from './hub';
import Cookies from 'js-cookie';
// console.log('Router', Router);

export const initUserInfo: Partial<UserInfo> = {
  uid: -1, // -1 获取中，0 未登录
  head_portrait: '',
  nickname: '',
  show_uid: '',
  // ...
};

export const initUserVipInfo: VipList[] = [];

export type IsLogged = 'fetching' | 'logged' | 'logout';

export type Rights = 'vip' | 'wordNum' | 'num' | 'phone';

class UserInfoStore {
  userInfo = initUserInfo;
  userVipInfo = initUserVipInfo;
  userSetting: any = {};

  get isLogged(): IsLogged {
    if (this.userInfo.uid === -1) {
      return 'fetching';
    } else if (this.userInfo.uid === 0) {
      return 'logout';
    }
    return 'logged';
  }

  get usernameForShow() {
    return this.nickname || this.username || this.show_uid;
  }

  get ai_num() {
    return this.userInfo?.ai_num || 0;
  }

  set ai_num(num: number) {
    this.userInfo.ai_num = num;
  }

  get ai_word_num() {
    return this.userInfo?.ai_word_num || 0;
  }

  set ai_word_num(num: number) {
    this.userInfo.ai_word_num = num;
  }

  get head_portrait() {
    return this.userInfo?.head_portrait || '';
  }

  get username() {
    return this.userInfo?.username || '';
  }

  get nickname() {
    return this.userInfo?.nickname || '';
  }

  get commonVipInfo() {
    return this.userVipInfo.find((i) => i.vip_range === 1);
  }

  get isCommonVip() {
    return !!this.commonVipInfo;
  }

  get enjoyVipInfo() {
    return this.userVipInfo.find((i) => i.vip_range === 2);
  }

  get isForeverVip() {
    return this.userVipInfo.some((i) => i.is_forever === 1);
  }

  get isEnjoyVip() {
    return !!this.enjoyVipInfo;
  }

  get isVip() {
    return !!this.userInfo?.is_vip;
  }

  get show_uid() {
    return this.userInfo?.show_uid || '';
  }

  get isFirst() {
    return this.userInfo?.is_first === 1;
  }

  get isBanned() {
    return this.userInfo?.is_banned === 1;
  }

  get isSpecialUser() {
    // UserTypeNormal      int64 = 0 // 用户类型-普通
    // UserTypeInside      int64 = 1 // 用户类型-内部
    // UserTypeDevelop     int64 = 2 // 用户类型-开发
    // UserTypeTest        int64 = 3 // 用户类型-测试
    // UserTypePartTimeJob int64 = 4 // 用户类型-兼职
    // UserTypeGroupInside int64 = 5 // 用户类型-韩创内部
    // UserTypeFictitious  int64 = 7 // 用户类型-虚拟
    return (this.userInfo?.user_type as number) > 0;
  }

  get chatFontSize() {
    return this.userSetting?.chatFontSize || 14;
  }

  loginShow = false; // 是否显示登录弹窗

  rechargeType: false | 'vip' | 'wordNum' | 'num' = false; // 字数/vip 充值弹窗

  limitType: false | 'vip' | 'wordNum' | 'num' = false; // 字数/vip 限制弹窗

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    hubStore.set({ userInfoStore: this });
    this.init();
    // reaction(
    //   () => this.userInfo.uid,
    //   () => this.updateUserVipInfo(),
    // );
  }

  private *init() {
    if (getIsClient()) {
      // 解决next水合报错问题
      yield commonStore.whenNextReady();
      yield this.getUserInfo();
    }
  }

  *getUserInfo() {
    try {
      const userInfo: UserInfo | null = yield getUserInfo();
      if (!userInfo) {
        Object.assign(this.userInfo, { uid: 0 });
        return;
      }
      if (userInfo.head_portrait) {
        userInfo.head_portrait = userInfo.head_portrait.replace('http://', 'https://');
      }
      // userInfo.is_new_register = dayjs().isSame(userInfo.user_create_time * 1000, 'day');
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      Object.assign(this.userInfo, userInfo);
      Cookies.set('app_id', userInfo.kaiDaAppId);
      Cookies.set('authorization', userInfo.authorization);

      // @ts-ignore
      if (window && window?.clarity) {
        // @ts-ignore
        window?.clarity("set", "uuid", userInfo.show_uid)
      }
      if (userInfo.user_type) {
        console.log('内部账号登录');
      }

      yield this.getUserVipInfo();

      yield this.getUserSetting();
    } catch (e: any) {
      console.error('userinfo获取失败', e);
      Object.assign(this.userInfo, { uid: 0 });
    }
  }

  *getUserVipInfo() {
    try {
      if (this.isLogged !== 'logged') {
        this.userVipInfo = initUserVipInfo;
        return;
      }

      const { vip_list: userVipInfo } = yield getUserVipInfo();
      if (!userVipInfo) {
        this.userVipInfo = initUserVipInfo;
        return;
      }
      this.userVipInfo = userVipInfo;
    } catch (e: any) {
      console.error('userVipInfo获取失败', e);
      this.userVipInfo = initUserVipInfo;
    }
  }

  *logout() {
    yield logout();
    this.userInfo = { ...initUserInfo, uid: 0 };
    // cookieRemove(cookieAccessTokenKey);
    // cookieRemove('token');
    // cookieRemove('user_info');
    localStorage.removeItem('userInfo');
    // cookieRemove('userInfo');
    // yield this.getUserInfo();

    Router.push('/introduction');
  }

  toggleLoginShow(show?: boolean) {
    this.loginShow = show ?? !this.loginShow;
  }

  updateAIWordNum(num: number) {
    this.ai_word_num = num;
  }

  updateAINum(num: number) {
    this.ai_num = num;
  }

  updateRechargeType(type: typeof userInfoStore.rechargeType = false) {
    this.rechargeType = type;
  }

  updateLimitType(type: typeof userInfoStore.limitType = false) {
    this.limitType = type;
  }

  *updateUserInfo() {
    yield this.getUserInfo();
    return this.userInfo;
  }

  updateUserVipInfo() {
    this.getUserVipInfo();
    return this.userVipInfo;
  }

  checkRights(
    fn?: () => void | undefined,
    rights?: Rights[], // 传空表示仅检查是否登录
    {
      alert = true,
      isMessage = true,
      isLimit = false,
      rejectFn,
    }: {
      alert?: boolean;
      isMessage?: boolean;
      isLimit?: boolean;
      rejectFn?: (reason: 'login' | Rights) => void;
    } = {},
  ): boolean {
    // 首先判断是否登录
    // if (this.isLogged !== 'logged') {
    // 处理路由包含如果路由路径包含'aibot/uNew/
    if (this.isLogged !== 'logged' && !/aibot\/uNew\//.test(window.location.pathname)) {
      if (alert) {
        this.toggleLoginShow(true);
        // if (isMessage) message.info('请先登录');
      }
      rejectFn?.('login');
      return false;
    }

    if (
      rights?.includes('phone') &&
      !this.userInfo.phone &&
      !/aibot\/uNew\//.test(window.location.pathname)
    ) {
      if (alert) {
        commonShowBindPhoneModal();
        // if (isMessage) {
        //   message.info({ key: 'pleaseBingPhone', content: '请先绑定手机号' });
        // }
      }
      rejectFn?.('phone');
      return false;
    }

    if (rights?.includes('vip') && !this.isVip && !/aibot\/uNew\//.test(window.location.pathname)) {
      if (alert) {
        if (isLimit) {
          buryPointMemo('会员限制弹窗');
          this.updateLimitType('vip');
        } else {
          buryPointMemo('会员充值弹窗');
          this.updateRechargeType('vip');
          if (isMessage) {
            message.info({ key: 'pleaseJoinVip', content: '请开通VIP' });
          }
        }
      }
      rejectFn?.('vip');
      return false;
    }

    if (
      (rights?.includes('wordNum') || rights?.includes('num')) &&
      !this.ai_word_num &&
      !this.isEnjoyVip &&
      !/aibot\/uNew\//.test(window.location.pathname)
    ) {
      if (alert) {
        if (!this.checkRights(undefined, ['vip'], { isMessage: false })) {
          // 如果用户不是vip，用完次数之后优先让用户充vip
          return false;
        }
        buryPointMemo('AI字数充值弹窗');
        this.updateRechargeType('wordNum');
        if (isMessage) {
          message.info({ key: 'noWordNum', content: 'AI字数不足' });
        }
      }
      rejectFn?.('wordNum');
      return false;
    }

    // if (rights?.includes('num') && !this.ai_num && !this.isEnjoyVip) {
    //   if (!this.ai_word_num) {
    //     if (!this.checkRights(undefined, ['vip'], { isMessage: false })) {
    //       // 如果用户不是vip，用完次数之后优先让用户充vip
    //       return false;
    //     }
    //     if (alert) {
    //       buryPointMemo('AI字数充值弹窗');
    //       this.updateRechargeType('num');
    //       if (isMessage) {
    //         message.info({ key: 'noNum', content: 'AI次数不足' });
    //       }
    //     }
    //     rejectFn?.('num');
    //     return false;
    //   }
    // }

    fn?.();
    return true;
  }

  checkHaveRights(rights?: Rights[], { alert } = { alert: false }) {
    return this.checkRights(() => {}, rights, { alert });
  }

  checkLogged() {
    return this.checkRights();
  }

  logoff() {
    logoff({});
  }

  *getUserSetting() {
    try {
      const { custom_config: s }: { custom_config: string } = yield getUserSetting();
      this.userSetting = s ? JSON.parse(s) : {};
      autorun(() => saveUserSetting({ setting: JSON.stringify(this.userSetting) }));
    } catch (e: any) {
      console.error('userSetting获取失败', e);
    }
  }

  updateChatFontSize(size: number) {
    this.userSetting.chatFontSize = size;
  }
}

// 方便编辑器导入提示，需要这样写
const userInfoStore = new UserInfoStore();
export default userInfoStore;

// for debug
if (getIsClient()) {
  (window as any).__userInfoStore = userInfoStore;
}
