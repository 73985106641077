import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { buryPointMemoDebounce } from '@/utils/buryPoint';
// import dayjs from 'dayjs';
// import commonStore from '@/stores/common';

// let first_url: any;

export function useRouterChange() {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      // if (commonStore.from === 'aizj') {
      //   if (!first_url) {
      //     first_url = url;
      //   } else {
      //     window.open(`https://changwenai.com${url}`, '_blank');
      //   }
      // }
      buryPointMemoDebounce('访问');
    };

    const resizeObserver = new ResizeObserver(() => {
      // const today = dayjs();
      // const formattedDate = today.format('YYYY-MM-DD');
      // const lastVisitedDate = localStorage.getItem('lastVisitedDate');
      // if (lastVisitedDate !== formattedDate) {
      //   buryPointMemoDebounce('今日首次访问-可见');
      //   localStorage.setItem('lastVisitedDate', formattedDate);
      // }
      buryPointMemoDebounce('访问');
      resizeObserver.unobserve(document.body);
    });

    resizeObserver.observe(document.body);

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);
}
